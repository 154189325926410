import React from "react";
import { Route } from "react-router";
import { List } from "./List";
import { View } from "./View";

export const Router: React.FC = () => {
  return (
    <>
      <Route path="/organizer/room-blocks" exact component={List} />
      <Route path="/organizer/room-blocks/:id" component={View} />
    </>
  )
};
