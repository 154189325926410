import React from "react";

import './SplashScreen.scss';
import { Card, CardBody } from "reactstrap";

const randomSplashScreenQuotes = [
  "",
  "[Placeholder quote]",
  "Up, up, down, down, left, right, left, right, B, A, Start!",
  "*suspenseful music*",
  "https://www.youtube.com/watch?v=ZnHmskwqCCQ",
  "No one will ever read this... :(",
  "Keep calm and carry on... waiting... for the app to load...",
  "I'm sorry, {user}. I'm afraid I can't do that.",
  "Yes, there's a lot of quotes. No, I'm not sorry.",
  "Is there a small loading delay? Yes. Is it worth it? Absolutely... not.",
  "Feeding the hamsters...",
  "Feeding the hamsters... again...",
  "Here, have some bread. 🍞",
  "// TODO: Add more quotes",
  "Hey Siri. Hey Alexa. Hey Google. Hey Cortana. Hey Bixby. Hey {user} 👋",
  "Help! I'm trapped in a loading screen!",
  "By the time you read this, the app will have loaded.",
  "I'm not sure what to put here. I'm just a loading screen.",
  "Time flies like an arrow. Fruit flies like a banana.",
  "Hope you're having a good day!",
  "Cats > Dogs",
  "ConCat-enate: to put things together as a connected series. (Now you know!)",
  "Beam me up, Scotty!",
  "Spoon!",
  "We found a bunny in our database! 🐇 🐇 🐇",
  "Cleaning up a hairball left by the ConCat Cat...",
  "Untangling a cat from our cables...",
  "Filling the database with cat pictures...",
  "Filtering out awoos...",
  "Filling the database with awoos...",
  "Loading... but with a cat!",
  "Loading... but with a dog!",
  "Waiting for the hamster to finish their coffee...",
  "Oh no! It's loading! Quick, do something else!",
  "Loading... but with a splash of color!",
  "Help! I'm trapped in a loading screen factory!",
  "Randomly generating quotes...",
  "Meow! 🐱",
  "Woof! 🐶",
  "Reticulating splines...",
  "Finding room availability...",
  "Faster than a speeding bullet! More powerful than a locomotive! Able to-- ...wait, wrong quote.",
];

export const SplashScreen: React.FC = () => {
  const quote = randomSplashScreenQuotes[Math.floor(Math.random() * randomSplashScreenQuotes.length)];

  return (
    <div className="splash-screen">
      <Card className="splash-screen__card">
        <CardBody>
          <div className="splash-screen__text">
            <h3>Loading...</h3>
            {quote && quote?.length > 0 && (
              <p className="splash-screen__quote">{quote}</p>
            )}
          </div>
          <div className="splash-screen__logo">
            <img src="https://cdn.concat.app/static/powered_by_concat.svg" alt="Powered by Concat" />
          </div>
        </CardBody>
      </Card>
    </div>
  )
};
