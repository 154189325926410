import React from "react";
import { Card, CardBody } from "reactstrap";
import { MaterialIcon } from "./MaterialIcon";

import './EmptyState.scss';

interface Props {
  title: string;
  details: (JSX.Element | string)[];
  icon: string;
}

export const EmptyState: React.FC<Props> = ({ title, details, icon }) => {
  return (
    <Card className="empty-state">
      <CardBody className="empty-state-body">
        <div>
          <MaterialIcon name={icon} large />
        </div>
        <div>
          <h4 className="mb-2">{title}</h4>
          {details.map((detail, idx) => <p key={idx}>{detail}</p>)}
        </div>
      </CardBody>
    </Card>
  );
};
