import React, { useCallback } from "react";
import { GlobalStateAction, useConfig, useDispatch, useUser } from "../services";
import { LoginModal } from "./LoginModal";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Badge } from "reactstrap";
import { renderName } from "../utils";
import { useHistory } from "react-router-dom";
import { User } from "../../common/models";
import fontColorContrast from 'font-color-contrast';

import './LoginContext.scss';

export const LoginContext: React.FC = () => {
  const [showLoginModal, setShowLoginModal] = React.useState(false);

  const { convention } = useConfig()!;
  const user = useUser();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    api.logout()
      .then(() => dispatch({ type: GlobalStateAction.SetUser, payload: null }));
  }, []);

  const renderBadges = useCallback((user: User) => {
    const roleWithBadge = user
      .roles?.sort((a, b) => b.weight - a.weight)
      .find(role => role.badge);

    if (roleWithBadge) {
      const badge = roleWithBadge.badge!;
      const color = badge.color ?? 'gray';

      return (
        <Badge
          style={{
            backgroundColor: color,
            color: fontColorContrast(badge.color, 0.6)
          }}
          className='userinfo__badge'
        >
          {badge.icon && <i className={`fa fa-${badge.icon}`} />}
          {badge.text}
        </Badge>
      )
    }

    return null;
  }, [user]);

  if (user) {
    return (
      <UncontrolledDropdown>
        <DropdownToggle color='link' className='userinfo__loggedin' caret>
          {renderBadges(user)}<strong>{renderName(user)}</strong>
        </DropdownToggle>
        <DropdownMenu right>
          {user.hasPermission('reservation:admin') && (
            <>
              <DropdownItem onClick={() => history.push('/organizer')}>Organizer Dashboard</DropdownItem>
              <DropdownItem divider />
            </>
          )}
          <DropdownItem className='text-danger' onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  return (
    <>
      {showLoginModal && <LoginModal onClose={() => setShowLoginModal(false)} />}
      <Button outline color="light" onClick={() => setShowLoginModal(true)}>
        Sign in with {convention.shortName}
      </Button>
    </>
  )
};
