import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Footer, Header, SystemError } from './component';
import { DrawSearch, Homepage, LoginAdBlock, LoginPage } from './pages';
import { GlobalStateAction, useConfig, useDispatch, useGlobalState, useUser } from './services';
import { SplashScreen } from './component/SplashScreen';
import { ToastContainer } from 'react-toastify';
import { EmptyState } from './component/common';
import { OrganizerRouter } from './pages/organizer';
import { useHeaderTitle } from './utils';

export const BookingBunApp: React.FC = () => {
  const config = useConfig();
  const user = useUser();
  const NavigationElement = useGlobalState(cb => cb.navigationElement);
  const dispatch = useDispatch();
  const [configError, setConfigError] = useState<Error | null>(null);

  useEffect(() => {
    useHeaderTitle('Loading... - BookingBun');

    if (SENTRY_DSN) {
      console.log("[Sentry.io] Initializing...");
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          }),
          new Sentry.BrowserProfilingIntegration(),
        ],
        tracesSampleRate: ENVIRONMENT === 'production' ? 0.1 : 1.0,
        tracePropagationTargets: ['localhost', '127.0.0.1', '/api'],
        profilesSampleRate: ENVIRONMENT === 'production' ? 0.1 : 1.0,
      })
    }

    // If the user is logging in via the popup window, then we need to
    // send a message back to the parent window to update the status.
    if (window.opener != null && window.location.pathname === '/login') {
      const params = new URLSearchParams(window.location.search);
      const error = params.get('error');

      (window.opener as Window).postMessage(
        JSON.stringify({ error, ok: error === null }), window.location.origin,
      );

      setTimeout(() => {
        window.close();
      }, 1000);

      return;
    }

    // Retrieve the system configuration and the active user.
    api.getConfig()
      .then(config => {
        api.getActiveUser()
          .then(user => {
            dispatch({ type: GlobalStateAction.SetUser, payload: user });
          })
          .catch(() => { /* Ignore */ });

        // Wait a bit before setting the config so that the splash screen
        // doesn't flash appear and disappear, and the user data is loaded.
        setTimeout(() => {
          useHeaderTitle(config.convention.longName + ' - BookingBun');
          dispatch({ type: GlobalStateAction.SetConfig, payload: config });
        }, 1500);
      })
      .catch(setConfigError);
  }, []);

  useEffect(() => {
    if (SENTRY_DSN) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user?.id.toString() });
        scope.setTag('organizationId', 'TBC');
      });
    }
  }, [user, config]);

  if (configError) {
    return <SystemError error={configError} />;
  }

  if (!config) {
    return <SplashScreen />;
  }

  return (
    <BrowserRouter>
      <div>
        <ToastContainer
          autoClose={7000}
          newestOnTop
          draggable
          pauseOnFocusLoss
        />
        <Header />
        {NavigationElement && <NavigationElement.element {...NavigationElement.props} />}
        <div className="main-content">
          <Switch>
            <Route path="/organizer">
              <OrganizerRouter />
            </Route>
            <Route path="/auth/continue" component={LoginAdBlock} />
            <Route path="/login" exact component={LoginPage} />
            <Route path="/" exact component={Homepage} />
            <Route path="/draw/:id/search" exact component={DrawSearch} />
            <Route
              render={() =>
                <EmptyState
                  title="Page Not Found"
                  details={[
                    'The page you are looking for does not exist.',
                  ]}
                  icon='warning'
                />
              }
            />
          </Switch>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};
