import React, { useEffect, useState } from "react";
import { Card, CardBody, Button, UncontrolledTooltip, Table, Badge } from "reactstrap";
import { RoomBlock } from "../../../../common/models";
import { captureError, getAvailableRoomCount } from "../../../utils";
import { MaterialIcon } from "../../../component/common";
import { useConfig } from "../../../services";
import { Link } from "react-router-dom";

export const List: React.FC = () => {
  const [rooms, setRooms] = useState<RoomBlock[] | null>(null);

  useEffect(() => {
    api.getAllRoomBlocks()
      .then(setRooms)
      .catch(captureError);
  }, []);

  return (
    <div>
      <Card>
        <CardBody className="badge-list align-center">
          <Button id="add-room" color="primary" className="button-flex" disabled>
            <MaterialIcon name="add" />
            Add Room
          </Button>
          <UncontrolledTooltip placement="bottom" target="add-room" trigger="hover">
            Only third-party integrations are currently supported.
          </UncontrolledTooltip>
          <div className="flex-expand" />
          <div>
            <Button id="sync-with-passkey" color="light" className="button-flex" outline disabled>
              <MaterialIcon name="sync" />
              Sync with Passkey
            </Button>
            <UncontrolledTooltip placement="bottom" target="sync-with-passkey" trigger="hover">
              Manual syncronization can only be run once every 24 hours.
            </UncontrolledTooltip>
          </div>
        </CardBody>
      </Card>
      <hr />
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Room Name</th>
                <th>Hotel Name</th>
                <th>Max Occupancy</th>
                <th>Core Nights Available</th>
                <th>Status</th>
                <th style={{ maxWidth: '120px' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rooms?.map(room => (
                <RoomBlockListItem
                  key={room.id}
                  room={room}
                />
              ))}
              {rooms === null && (
                <EmptyListItem />
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

interface RoomBlockListItemProps {
  room: RoomBlock;
}

const RoomBlockListItem: React.FC<RoomBlockListItemProps> = ({ room }) => {
  const config = useConfig()!;
  const coreNights = getAvailableRoomCount(room, config.convention.startAt, config.convention.endAt);

  return (
    <tr>
      <td>{room.id}</td>
      <td>{room.name}</td>
      <td>{room.hotel!.name!}</td>
      <td>{room.maxPersons}</td>
      <td>{coreNights}</td>
      <td>
        <div className="badge-list">
          {room.visibility ? <Badge color="success">Visible</Badge> : <Badge color="secondary">Hidden</Badge>}
          {coreNights === 0 && <Badge color="danger">Sold Out</Badge>}
        </div>
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          <Link to={`/organizer/room-blocks/${room.id}`}>
            <MaterialIcon name="edit" />
          </Link>
        </div>
      </td>
    </tr>
  );
}

const EmptyListItem: React.FC = () => {
  return (
    <tr className="placeholder-glow">
      <td>
        <div className="placeholder col-4" />
      </td>
      <td>
        <div className="placeholder col-10" />
      </td>
      <td>
        <div className="placeholder col-8" />
      </td>
      <td>
        <div className="placeholder col-4" />
      </td>
      <td>
        <div className="placeholder col-4" />
      </td>
      <td>
        <div className="placeholder col-4" />
      </td>
      <td>
        <div className="placeholder col-6" />
      </td>
    </tr>
  )
};