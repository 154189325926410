import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import { EmptyState, InfoCard, MetricBlock } from "../../component/common";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Link } from "react-router-dom";
import { OrganizerDashboard as OrganizerDashboardModel, ReleaseSchedule } from "../../../common/models";
import { WaveMetric, WaveScheduleItem } from "../../component";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = 'white';

export const OrganizerDashboard: React.FC = () => {
  const [data, setData] = useState<OrganizerDashboardModel | null>(null);

  useEffect(() => {
    api.getOrganizerDashboardStats().then(setData);
  }, []);

  return (
    <div>
      <DashboardMetrics data={data} />
      <hr />
      <WaveInfo data={data} />
    </div>
  );
};

interface Props {
  data: OrganizerDashboardModel | null;
}

const DashboardMetrics: React.FC<Props> = ({ data }) => {
  const unbookableRooms = data?.totals.unbookableRooms;

  return (
    <>
      <Row>
        <Col xs={6} md={4} lg={3}>
          <MetricBlock
            icon="bed"
            title="Total reservations"
            value={data?.totals.reservations}
          />
        </Col>
        <Col xs={6} md={4} lg={3}>
          <MetricBlock
            icon="event_available"
            title="Rooms available"
            value={data?.totals.rooms}
          />
        </Col>
        <Col xs={6} md={4} lg={3}>
          <MetricBlock
            icon="event_busy"
            title="Unbookable rooms"
            value={unbookableRooms}
            helpText="Rooms that do not have availability for attendees to book across the core event dates but are not entirely sold out."
            status={unbookableRooms && unbookableRooms > 0 ? 'warning' : undefined}
          />
        </Col>
        <Col xs={6} md={4} lg={3}>
          <MetricBlock
            icon="assessment"
            title="Uptake rate"
            value={data?.totals.uptakeRate !== undefined ? `${data?.totals.uptakeRate.toFixed(2)}%` : undefined}
            helpText="The percentage of rooms that have been booked by attendees, out of the total number of rooms available."
          />
        </Col>
      </Row>
      {(unbookableRooms !== undefined && unbookableRooms > 0) && (
        <Alert color="warning" fade={false}>
          <strong>Warning</strong>: There are currently {unbookableRooms} room nights that are not available for attendees to book due to one or more nights being sold out.
        </Alert>
      )}
    </>
  );
};

const WaveInfo: React.FC<Props> = ({ data }) => {
  const waves = data?.waves;
  const waveCount = waves?.length;
  const loading = waveCount === undefined;

  return (
    <Row>
      <Col xs={12} md={6} lg={8}>
        <Card>
          <CardBody>
            <h4>Active Waves</h4>
            <hr />
            <WaveMetrics waves={waves} />
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Card>
          <CardBody>
            <h4>Wave Schedule</h4>
            <hr />
            {loading && (
              <InfoCard
                title="Loading..."
                loading
              />
            )}
            {waves?.map(wave => (
              <WaveScheduleItem key={wave.id} wave={wave} />
            ))}
            {!loading && waveCount === 0 && (
              <EmptyState
                icon="event_busy"
                title="No waves"
                details={["There are no waves scheduled yet."]}
              />
            )}
            <div className='mt-3'>
              <Link to="/organizer/waves" className='text-white'>
                View all waves
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
};

interface WaveMetricsProps {
  waves?: ReleaseSchedule[];
}

const WaveMetrics: React.FC<WaveMetricsProps> = ({ waves }) => {
  const activeWaves = waves?.filter(wave => wave.status !== 'scheduled' && wave.status !== 'closed');

  return (
    <div>
      {!waves && <WaveMetric />}
      {activeWaves?.map(wave => (
        <WaveMetric
          key={wave.id}
          wave={wave}
        />
      ))}
      {activeWaves && activeWaves.length === 0 && (
        <EmptyState
          icon="event_busy"
          title="No active waves"
          details={["There are no active waves at this time."]}
        />
      )}
    </div>
  )
};
