import React from 'react';

export const Footer: React.FC = () => {
  return (
    <footer className='mt-auto'>
      <div className="pt-2 pb-2 pl-4 pr-4">
        <a href="https://concat.app" rel="noopener noreferrer" target="_blank">
          <img src="https://cdn.concat.app/static/powered_by_concat.svg" />
        </a>
        <div>
          <small>
            Version: {VERSION}
          </small>
        </div>
      </div>
    </footer>
  )
};
