import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { Row, Col, Card, CardBody, Badge, Button, FormGroup, Label, Input, FormText } from "reactstrap";
import { EmailTemplate } from "../../../../../common/models";
import { toast } from "react-toastify";
import { captureError, useDebounceValue } from "../../../../utils";
import { Preview } from "./Preview";

interface EditorProps {
  template: EmailTemplate;
  onBack(): void;
}

export const Editor: FC<EditorProps> = ({ template, onBack }) => {
  const [previewTemplate, setPreviewTemplate] = useState<EmailTemplate>(template);
  const debouncedPreviewTemplate = useDebounceValue(previewTemplate, 1000);

  const onChangeEditingTemplate = useCallback((key: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPreviewTemplate({
      ...previewTemplate,
      [key]: value,
    });
  }, [previewTemplate, setPreviewTemplate]);

  const doSaveChanges = useCallback(() => {
    api.updateEmailTemplate(previewTemplate)
      .then(() => {
        toast.success(`Saved changes to template ${template.key}!`);
      })
      .catch(captureError);
  }, [previewTemplate]);

  return (
    <div>
      <Row>
        <Col xs={12} className="mb-2">
          <Card>
            <CardBody className="card-item-header">
              <div>
                <h4>
                  Template - {template.key}{' '}
                  <small>(ID: {template.id})</small>
                </h4>
                <div className="mb-1">
                  <small>
                    <strong>Created</strong> {template.createdAt.toLocaleString()} |{' '}
                    <strong>Last updated</strong> {template.updatedAt.toLocaleString()}
                  </small>
                </div>
                <div className="badge-list">
                  <Badge color="success">Active</Badge>
                </div>
              </div>
              <div>
                <Button
                  color='light'
                  onClick={onBack}
                  outline
                >
                  Back to Email Templates
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} xs={12} className="mb-2">
          <Card>
            <CardBody>
              <h4>Editor</h4>
              <FormGroup>
                <Label for="from">From Alias</Label>
                <Input
                  id="from"
                  name="from"
                  value={previewTemplate.from}
                  onChange={e => onChangeEditingTemplate('from', e)}
                />
                <FormText color="light">
                  The alias is the username section of the email. For example, entering "hotel" with a domain of concat.app would result in a from email of "hotel@concat.app".
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for="subject">Subject</Label>
                <Input
                  id="subject"
                  name="subject"
                  value={previewTemplate.subject}
                  onChange={e => onChangeEditingTemplate('subject', e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="body">Body</Label>
                <Input
                  id="body"
                  name="body"
                  style={{ minHeight: '300px' }}
                  type="textarea"
                  value={previewTemplate.body}
                  onChange={e => onChangeEditingTemplate('body', e)}
                />
              </FormGroup>
              <hr />
              <div>
                <Button color="primary" onClick={doSaveChanges}>
                  Save Changes
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} xs={12}>
          <Card>
            <CardBody>
              <h4>Preview</h4>
              <hr />
              <Preview template={debouncedPreviewTemplate} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}