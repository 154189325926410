import { ErrorResponse as ErrorResponseBase, makeReporter } from '@conventioncatcorp/common-fe/dist/errorHandling';
import { toast } from 'react-toastify';
import { APIError } from "@conventioncatcorp/common-fe/dist/APIClient";
import { LogicError } from '../../common/errorCodes';

export interface ErrorResponse extends ErrorResponseBase<LogicError> {

}

export const captureError = makeReporter({
  showError(content) {
    toast.error(() => content);
  },
  errorMessages: {
  },
});

export function isResourceError(err: unknown, resource?: string): err is APIError {
  return (
    err instanceof APIError &&
    !!err.apiResponse.errors.resource &&
    (resource ? err.apiResponse.errors.resource.name === resource : true)
  );
}

export function isAccessError(err: Error, permission?: PermissionName): err is APIError {
  if (!(err instanceof APIError)) {
    return false;
  }

  const { access } = err.apiResponse.errors;
  return !!(permission ? access?.permissions?.includes(permission) : access);
}

export function isLogicError(err: unknown, code?: LogicError): err is APIError {
  return (
    err instanceof APIError &&
    !!err.apiResponse.errors.logic &&
    (code ? err.apiResponse.errors.logic.some((le) => le.code === code) : true)
  );
}

export function isAuthError(err: Error): err is APIError {
  if (!(err instanceof APIError)) {
    return false;
  }

  const { authentication } = err.apiResponse.errors;
  return !!authentication && authentication.code === -1;
}
