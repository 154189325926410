import React from "react";
import { Card, CardBody, Badge } from "reactstrap";
import { ExpandableText, MaterialIcon } from "../component/common";
import { Hotel, RoomBlock } from "../../common/models";
import { cToUsdStrPref, classNames, getAvailableRooms, getLowestAveragePrice } from "../utils";
import { useConfig } from "../services";

import "./HotelSidenav.scss";

interface HotelSidenavPropsBase {
  selected?: boolean;
  onClick?: () => void;
}

interface HotelSidenavProps extends HotelSidenavPropsBase {
  hotel: Hotel;
  rooms: RoomBlock[];
}

export const AllHotelSidenav: React.FC<HotelSidenavPropsBase> = ({ selected, onClick }) => {
  return (
    <Card className={classNames({ 'hotel-selected': selected }, 'hotel', 'hotel-select-all')} onClick={onClick}>
      <CardBody>
        <div>
          <div className="hotel-info">
            <h4>Select All Hotels</h4>
          </div>
        </div>
      </CardBody>
    </Card>
  )
};

export const HotelSidenav: React.FC<HotelSidenavProps> = ({ hotel, rooms, selected, onClick }) => {
  const config = useConfig();

  const address = [
    hotel.addressLine1,
    hotel.addressLine2,
    hotel.addressCity,
    hotel.addressState,
    hotel.addressZipcode,
    hotel.addressCountry
  ].filter((line) => line).join(', ');

  const hotelRooms = rooms.filter(room => room.hotelId === hotel.id);
  const coreNightRooms = getAvailableRooms({ rooms: hotelRooms, checkIn: config?.convention.startAt, checkOut: config?.convention.endAt });
  const lowestAveragePrice = getLowestAveragePrice(coreNightRooms, config?.convention.startAt!, config?.convention.endAt!);

  const isSoldOut = coreNightRooms.length === 0;

  return (
    <Card className={classNames({ 'hotel-selected': selected }, 'hotel')} onClick={onClick}>
      <CardBody>
        <div className="hotel-header">
          <div className="hotel-info">
            <h4>{hotel.name}</h4>
            <div>
              <small>{address}</small>
            </div>
            <div className="hotel-badges badge-list">
              {hotel.isMainHotel && (
                <Badge color="primary">
                  <MaterialIcon name="star" />
                  {config?.convention.shortName} Main Venue Hotel
                </Badge> 
              )}
              {isSoldOut && (
                <Badge color="danger">
                  <MaterialIcon name="warning" />
                  Sold Out
                </Badge>
              )}
            </div>
          </div>
          {!isSoldOut && (
            <div className="hotel-lowest-price">
              <div className="hotel-lowest-price-text">
                <small>Rooms starting from</small>
              </div>
              <div className="hotel-lowest-price-amount">
                <h5>{cToUsdStrPref(lowestAveragePrice, { dropCentsIfZero: true })} <small>per night, avg.<br />(before taxes and fees)</small></h5>
              </div>
            </div>
          )}
        </div>
        {selected && (
          <div className="hotel-description">
            <ExpandableText
              text={hotel.description ?? 'No description available.'}
              maxLength={128}
            /> 
          </div>
        )}
      </CardBody>
    </Card>
  )
};
