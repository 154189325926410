import React, { useCallback } from "react";
import { InfoCard } from "../common";
import { ReleaseSchedule } from "../../../common/models";
import { millisecondsToDhms } from "../../utils";

interface Props {
  wave: ReleaseSchedule;
}

export const WaveScheduleItem: React.FC<Props> = ({ wave }) => {
  const waveStatus = useCallback(() => {
    if (!wave)
      return 'Loading...';

    const isVIPWave = wave.allowedRoles && wave.allowedRoles.length > 0;
    const type = wave.type === 'queue' ? 'FCFS queue' : 'Random draw';
    const waveType = `${isVIPWave ? 'VIP ' : ''}${type}`;

    if (wave.status === 'drawPending') {
      const dhms = millisecondsToDhms(wave.releaseStart.getTime() - Date.now(), { maxParts: 2 });
      return `${waveType}, winners announced in ${dhms}`;
    }

    if (wave.status === 'queueOpen') {
      const dhms = millisecondsToDhms(wave.entryEnd!.getTime() - Date.now(), { maxParts: 2 });
      return `${waveType}, entries close in ${dhms}`;
    }

    if (wave.status === 'scheduled') {
      const dhms = millisecondsToDhms((wave.entryStart ?? wave.releaseStart).getTime() - Date.now(), { maxParts: 2 });
      return `${waveType}, ${wave.type === 'queue' ? 'queue opens' : 'entries open'} in ${dhms}`;
    }

    if (wave.status === 'releaseOpen') {
      const dhms = millisecondsToDhms(wave.releaseEnd.getTime() - Date.now(), { maxParts: 2 });
      return `${waveType}, release ends in ${dhms}`;
    }

    return 'Unknown';
  }, [wave]);

  const tzc: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };

  const waveStart = wave.releaseStart.toLocaleDateString(undefined, tzc);
  const waveEnd = wave.releaseEnd.toLocaleDateString(undefined, tzc);

  return (
    <InfoCard
      title={`Wave ${wave.id}`}
      editLinkTo={`/organizer/waves/${wave.id}`}
    >
      <div>
        {waveStatus()}
      </div>
      <div>
        <small>{waveStart} to {waveEnd}</small>
      </div>
    </InfoCard>
  )
};
