import { APIClient as BaseAPIClient, RequestOptions } from '@conventioncatcorp/common-fe/dist';
import { Config, Hotel, ReleaseSchedule, User, QueueInfo, ReservationInfo, OrganizerDashboard, RoomBlock, EmailTemplate, EligibilityCheck, Reservation, StripeIntentResponse } from '../common/models';
import { isAuthError } from './utils';

export class APIClient {
  constructor(private readonly base: BaseAPIClient) {
  }

  public async getOAuthUrl(): Promise<{ url: string }> {
    return this.request({ url: '/api/login' });
  }

  public async getConfig(): Promise<Config> {
    return this.request<Config>({ url: '/api/config' });
  }

  public async getHotel(id: number): Promise<Hotel> {
    return this.request<Hotel>({ url: `/api/hotels/${id}` });
  }

  public async getHotels(fetchAdmin?: boolean): Promise<Hotel[]> {
    return this.request<Hotel[]>({
      url: '/api/hotels' + (fetchAdmin ? '' : '/public'),
    });
  }

  public async getAllRoomBlocks(): Promise<RoomBlock[]> {
    return this.request<RoomBlock[]>({
      url: '/api/rooms',
    });
  }

  public async getRoomBlock(id: number): Promise<RoomBlock> {
    return this.request<RoomBlock>({
      url: `/api/rooms/${id}`,
    });
  }

  public async logout(): Promise<void> {
    await this.request({
      method: 'delete',
      url: '/api/login',
    });
  }

  public async joinQueue(queueId: number, token?: string): Promise<void> {
    await this.base.request({
      method: 'post',
      payload: {
        token,
      },
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async leaveQueue(queueId: number): Promise<void> {
    await this.request({
      method: 'delete',
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async getQueueInfo(queueId: number): Promise<QueueInfo> {
    return this.request<QueueInfo>({
      url: `/api/release/${queueId}/queue`,
    });
  }

  public async getReleaseReservation(scheduleId: number): Promise<ReservationInfo> {
    return this.base.request<ReservationInfo>({
      url: `/api/release/${scheduleId}/reservationUrl`,
    });
  }

  public async getActiveUser(): Promise<User> {
    return this.base.request<User>({
      url: '/api/login/current',
    });
  }

  public async getOrganizerDashboardStats(): Promise<OrganizerDashboard> {
    return this.request<OrganizerDashboard>({
      url: '/api/organizer/dashboard',
    });
  }

  public async getAllReleases(): Promise<ReleaseSchedule[]> {
    return this.request<ReleaseSchedule[]>({
      url: '/api/release',
    });
  }

  public async getHotelReleaseSchedule(hotelId: number): Promise<ReleaseSchedule> {
    return this.request<ReleaseSchedule>({
      method: 'get',
      url: `/api/hotels/${hotelId}/release`,
    });
  }

  public async getReleaseEligibility(scheduleId: number): Promise<EligibilityCheck> {
    return this.request<EligibilityCheck>({
      url: `/api/release/${scheduleId}/eligibility`,
    });
  }

  public async getAllEmailTemplates(): Promise<EmailTemplate[]> {
    return this.request<EmailTemplate[]>({
      url: '/api/templates/emails',
    });
  }

  public async getEmailTemplatePreview(template: Record<string, unknown>): Promise<EmailTemplate> {
    return this.request<EmailTemplate>({
      method: 'post',
      payload: template,
      url: '/api/templates/emails/render',
    });
  }

  public async updateEmailTemplate(template: EmailTemplate): Promise<void> {
    return this.request<void>({
      method: 'put',
      payload: {
        body: template.body,
        from: template.from,
        subject: template.subject,
      },
      url: `/api/templates/emails/${template.key}`,
    });
  };

  public async getMyReservations(): Promise<Reservation[]> {
    return this.request<Reservation[]>({
      url: '/api/reservations',
    });
  }

  public async getReservation(id: string, reservationId: string): Promise<Reservation> {
    return this.request<Reservation>({
      url: `/api/reservations/${id}/${reservationId}`,
    });
  }

  public async getStripeIntent(): Promise<StripeIntentResponse> {
    return this.request<StripeIntentResponse>({
      url: '/api/payments/stripe',
    });
  }

  public async request<T extends object | void = void>(
    opts: RequestOptions<object | undefined> | string,
  ): Promise<T> {
    try {
      return await this.base.request<T>(opts);
    } catch (error) {
      const url = opts instanceof Object ? opts.url : opts;
      const noRedirect = url === '/api/login/current' || (url.startsWith('/api/release/') && url.endsWith('/queue'));

      // If we're not authenticated, redirect to the login page
      if (isAuthError(error as Error) && !noRedirect) {
        window.location.replace('/');
      }

      throw error;
    }
  }
}
