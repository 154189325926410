import { classNames } from "@conventioncatcorp/common-fe";
import { MaterialIcon } from "./common";
import { Criteria } from "../../common/models";
import React from "react";

import './CriteriaList.scss';

export interface CriteriaListProps {
  criteria: Criteria[];
  messages: {
    allCriteriaMet: string;
    someCriteriaMet: string;
    noCriteriaMet?: string;
  }
}

export const CriteriaList: React.FC<CriteriaListProps> = ({ criteria, messages }) => {
  const allCriteriaMet = criteria.every((criterion) => criterion.met);
  const someCriteriaMet = criteria.some((criterion) => criterion.met);

  return (
    <div className="criteria-list-component">
      <div>
        <small>
          {allCriteriaMet && messages.allCriteriaMet}
          {(!allCriteriaMet && someCriteriaMet) && messages.someCriteriaMet}
          {(!allCriteriaMet && !someCriteriaMet) && messages.noCriteriaMet}
        </small>
      </div>
      <ul className="criteria-list">
        {criteria.map((criterion, idx) => (
          <li
            key={idx}
            className={classNames({
              'criteria-met': criterion.met,
              'criteria-unmet': !criterion.met,
            }, 'criteria-item')}
          >
            <div className='criteria-condition'>
              <MaterialIcon name={criterion.met ? "check" : "close"} />
              {criterion.text}
            </div>
            {!criterion.met && (
              <div className='criteria-error-helptext'>
                &mdash; {criterion.errorHelpText}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
