import React, { useCallback, useContext } from "react";
import { GlobalState, GlobalStateDispatch, store } from "../RootReducer";
import { Config, UserActor } from "../../../common/models";

export function useGlobalState<T>(cb: (s: GlobalState) => T): T {
  return cb(useContext(store));
}

export function useUser(): UserActor | null {
  const user = useGlobalState(s => s.user);

  const hasPermission = useCallback((permission: string) => {
    return user?.roles.some(role => role.permissions?.includes(permission)) ?? false;
  }, [user]);

  const hasRole = useCallback((roleId: string) => {
    return user?.roles.some(role => role.id === roleId) ?? false;
  }, [user]);

  if (!user) {
    return null;
  }

  /**
   * This is a hack to add the hasPermission and hasRole functions to the user object.
   * 
   * Using { ...user, hasPermission, hasRole } would not work because the user object would be
   * recreated each time, resulting in React repeatedly re-rendering the component.
   */
  const userActor = user as UserActor;
  userActor.hasPermission = hasPermission;
  userActor.hasRole = hasRole;

  return userActor;
}

export function useConfig(): Config | null {
  return useGlobalState(s => s.config);
}

export function useLoadedState<T>(initialValue: T): [T, (a: T | Error) => void, Error | null] {
  const [state, setState] = React.useState<T>(initialValue);
  const [error, setError] = React.useState<Error | null>(null);

  const handleInput = useCallback((input: T | Error) => {
    if (input instanceof Error) {
      setError(input);
    } else {
      setState(input);
    }
  }, []);

  return [state, handleInput, error];
}

export function useDispatch(): React.Dispatch<GlobalStateDispatch> {
  const dispatch = useGlobalState(s => s.dispatch);

  if (!dispatch) {
    throw new Error('useDispatch must be used within a StoreProvider');
  }

  return dispatch;
}
