import React, { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Table, UncontrolledTooltip } from "reactstrap";
import { EmptyState, MaterialIcon } from "../../../component/common";
import { Hotel } from "../../../../common/models";
import { captureError, formatAddress } from "../../../utils";
import { Link } from "react-router-dom";

export const List: React.FC = () => {
  const [hotels, setHotels] = useState<Hotel[] | null>(null);

  useEffect(() => {
    api
      .getHotels(true)
      .then(setHotels)
      .catch(captureError);
  }, []);

  return (
    <div>
      <Card>
        <CardBody className="badge-list align-center">
          <Button id="add-hotel" color="primary" className="button-flex" disabled>
            <MaterialIcon name="add" />
            Add Hotel
          </Button>
          <UncontrolledTooltip placement="bottom" target="add-hotel" trigger="hover">
            Only third-party integrations are currently supported.<br />
            Please add a hotel through the Integrations page.
          </UncontrolledTooltip>
        </CardBody>
      </Card>
      <hr />
      <Card>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Hotel Name</th>
                <th>Address</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {hotels?.map((hotel) => (
                <HotelItem key={hotel.id} hotel={hotel} />
              ))}
              {hotels === null && <EmptyListItem />}
            </tbody>
          </Table>
          {hotels?.length === 0 && (
            <EmptyState
              title="No Hotels"
              details={[
                'You haven\'t added any hotels yet.',
                'Click the "Add Hotel" button above to get started, or use a third-party integration.'
              ]}
              icon='hotel'
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

interface HotelItemProps {
  hotel: Hotel;
}

const HotelItem: React.FC<HotelItemProps> = ({ hotel }) => {
  const formattedAddress = formatAddress(hotel);
  const managedBy = hotel.managedBy ?? (hotel.eventId ? 'Passkey' : 'Manual');

  return (
    <tr>
      <td>{hotel.id}</td>
      <td>{hotel.name}</td>
      <td>{formattedAddress}</td>
      <td>
        <div className="badge-list">
          {hotel.isMainHotel && <Badge color="primary">Main Hotel</Badge>}
          {managedBy === 'Manual' ? (
            <Badge color="secondary">Manual</Badge>
          ) : (
            <Badge color="warning">3rd Party: {hotel.integration!.name} ({hotel.integration!.type})</Badge>
          )}
        </div>
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          <Link to={`/organizer/hotels/${hotel.id}`}>
            <MaterialIcon name="edit" />
          </Link>
          <MaterialIcon name="delete" />
        </div>
      </td>
    </tr>
  );
};

const EmptyListItem: React.FC = () => {
  return (
    <tr className="placeholder-glow">
      <td>
        <div className="placeholder col-2" />
      </td>
      <td>
        <div className="placeholder col-6" />
      </td>
      <td>
        <div className="placeholder col-10" />
      </td>
      <td>
        <div className="placeholder col-4" />
      </td>
      <td>
        <div className="placeholder col-2" />
      </td>
    </tr>
  )
};
