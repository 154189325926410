import React from "react";
import { RoomAvailability } from "../../common/models";
import { cToUsdStrPref, classNames } from "../utils";

import './HotelRoomAvailability.scss';
import { MaterialIcon } from "./common";

interface Props {
  availability: RoomAvailability;
  showRoomCount?: boolean;
  isCoreNight?: boolean;
}

export const HotelRoomAvailability: React.FC<Props> = ({
  availability: { available, availableAt, roomRate, roomCount },
  isCoreNight = false,
  showRoomCount = false,
}) => {
  const soldOut = roomCount ? roomCount === 0 : !available;
  const day = availableAt.toLocaleString('en-US', { weekday: 'short', timeZone: 'UTC' });
  const date = availableAt.toLocaleString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' });

  return (
    <div className={classNames({ 'sold-out': soldOut }, "hotel-room-availability")}>
      {isCoreNight && (
        <div className="hotel-room-availability-core-night">
          <MaterialIcon title="Core Night" name="star" />
        </div>
      )}
      <div className="hotel-room-availability-main">
        <div className="hotel-room-availability-day">{day}</div>
        <div className="hotel-room-availability-date">{date}</div>
        <div className="hotel-room-availability-info">
          {(showRoomCount && (roomCount ?? 0 > 0)) ? (
            <>
              <div className="hotel-room-availability-count">
                {roomCount} Available
              </div>
              <div className="hotel-room-availability-divider" />
            </>
          ) : null}
          <div className="hotel-room-availability-price">
            {soldOut ? 'Sold Out' : cToUsdStrPref(roomRate)}</div>
          </div>
      </div>
    </div>
  )
};