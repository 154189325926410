import React from "react";
import { InlineHelp } from "@conventioncatcorp/common-fe";
import { Card, CardBody } from "reactstrap";
import { MaterialIcon } from "./MaterialIcon";

import './MetricBlock.scss';

type MetricBlockStatus = 'warning' | 'error' | 'success';

interface MetricBlockProps {
  icon: string;
  title: string;
  value?: string | number;
  helpText?: React.ReactNode;
  status?: MetricBlockStatus;
}

export const MetricBlock: React.FC<MetricBlockProps> = ({ helpText, icon, status, title, value }) => {
  return (
    <Card className="metricblock">
      <CardBody>
        <div className="metricblock-icon">
          <MaterialIcon name={icon} />
        </div>
        <div className="metricblock-info">
          <div className="metricblock-title">
            {title}
            {helpText && (
              <InlineHelp>
                {helpText}
              </InlineHelp>
            )}
          </div>
          <div className="metricblock-body">
            {status && (
              <div className="metricblock-statusIcon">
                <MaterialIcon name="warning" className={`text-${status}`} />
              </div>
            )}
            <div className="metricblock-value">
              {value !== undefined ? value : <div className="placeholder-glow"><div className="placeholder col-12" /></div>}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
