import { Addressed } from "../../common/models";

export const formatAddress = (address: Addressed): string => {
  const {
    addressCity,
    addressCountry,
    addressLine1,
    addressLine2,
    addressState,
    addressZipcode,
  } = address;

  const parts = [
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressZipcode,
    addressCountry,
  ].filter(Boolean);

  return parts.join(', ');
};
