import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { AllHotelSidenav, HotelSidenav, HotelRoom, HotelRoomSearch, SearchFilter } from "../component";
import { Link, useHistory } from "react-router-dom";
import { GlobalStateAction, NavigationElement, useDispatch } from "../services";
import { ReleaseSchedule } from "../../common/models";
import { getAvailableRooms, renderDateRange, renderDisplayName, useQueueInfo } from "../utils";
import { Countdown, EmptyState } from "../component/common";

import './DrawSearch.scss';

export const DrawSearch: React.FC = () => {
  const [release, setRelease] = useState<ReleaseSchedule | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [searchFilter, setSearchFilter] = useState<SearchFilter | null>(null);
  const [selectedHotel, setSelectedHotel] = useState<number | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Get the release ID from the URL
    const releaseId = window.location.pathname.split('/')[2];

    api.getAllReleases()
      .then((releases) => {
        const release = releases.find((release) => release.id === parseInt(releaseId!));

        if (release && release.showRoomAvailability) {
          setRelease(release);
        }
      })
      .finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    if (!release)
      return;

    const navElement: NavigationElement = {
      element: HotelSearchNavElement,
      props: {
        release,
      }
    };

    dispatch({ type: GlobalStateAction.SetNavigationElement, payload: navElement });

    return () => {
      dispatch({ type: GlobalStateAction.SetNavigationElement, payload: null });
    }
  }, [release]);

  if (!loaded)
    return null;

  if (!release) {
    history.push('/');
    return null;
  }

  const hotels = release.hotels?.sort((a, b) => a.name.localeCompare(b.name));

  const sortedRoomBlocks = release.roomBlocks?.sort((a, b) => {
    const hotelA = hotels?.find(hotel => hotel.id === a.hotelId);
    const hotelB = hotels?.find(hotel => hotel.id === b.hotelId);

    if (!hotelA || !hotelB)
      return 0;

    return hotelA.name.localeCompare(hotelB.name);
  });

  const roomBlocks = sortedRoomBlocks?.filter(roomBlock => {
    if (selectedHotel !== null && roomBlock.hotelId !== selectedHotel)
      return false;

    if (!searchFilter)
      return true;

    const { checkIn, checkOut, minBeds, minGuests } = searchFilter;

    const roomAvailable = getAvailableRooms({ rooms: [roomBlock], checkIn, checkOut });

    if (roomAvailable.length === 0)
      return false;

    return roomBlock.maxPersons >= minGuests && Math.ceil(roomBlock.maxPersons / 2) >= minBeds;
  });

  return (
    <div className="hotel-list">
      <Row style={{ justifyContent: 'center' }}>
        <Col lg={4}>
          <AllHotelSidenav
            selected={selectedHotel === null}
            onClick={() => setSelectedHotel(null)}
          />
          {release.hotels?.map(hotel => (
            <HotelSidenav
              selected={selectedHotel === hotel.id}
              key={hotel.id}
              rooms={release.roomBlocks ?? []}
              hotel={hotel}
              onClick={() => setSelectedHotel(hotel.id)}
            />
          ))}
        </Col>
        <Col lg={8}>
          <Card className='mb-3'>
            <HotelRoomSearch onChange={setSearchFilter} />
          </Card>
          <Card>
            {!roomBlocks || roomBlocks.length === 0 ? (
              <EmptyState
                icon='hotel'
                title='No rooms found'
                details={[
                  'No rooms were found for the selected criteria.',
                  'Please try modifying your check-in/check-out dates, or the number of guests/beds.'
                ]}
              />
            ) : (
              <CardBody>
                <div className="hotel-room-list">
                  {roomBlocks?.map(roomBlock => {
                    const hotel = hotels?.find(hotel => hotel.id === roomBlock.hotelId);

                    return (
                      <HotelRoom key={roomBlock.id} hotel={hotel!} room={roomBlock} search={searchFilter} />
                    );
                  })}
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
};

interface NavElementProps {
  release?: ReleaseSchedule;
}

const HotelSearchNavElement: React.FC<NavElementProps> = ({ release }) => {
  if (!release)
    return null;

  return (
    <>
      <div className="hotel-search-alert-element">
        <Link className="btn btn-block btn-outline-light" to="/">Return to Wave Info</Link>
        <div>
          Viewing rooms available in <strong>{renderDisplayName(release)}</strong><br />
          <small>Wave dates: {renderDateRange(release!.releaseStart, release!.releaseEnd)}</small>
        </div>
        <div className="gap" />
        <div>
          <strong>Please note</strong>: This inventory reflects availability from the last update cycle and is not live updated.<br />
          <strong>This page is not used to reserve a room</strong>, but to view available rooms and their details.
        </div>
      </div>
      <RoomBookingAlert release={release} />
    </>
  );
};

interface RoomBookingAlertProps {
  release: ReleaseSchedule | null;
}

const RoomBookingAlert: React.FC<RoomBookingAlertProps> = ({ release }) => {
  const { queueInfo } = useQueueInfo(release ?? null);

  if (!queueInfo || !queueInfo.drawn || !queueInfo.reservationInfo)
    return null;

  return (
    <div className="hotel-search-alert-element hotel-search-alert-element--success">
      <a
        href={queueInfo.reservationInfo.url}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-block btn-primary"
      >
        Proceed to Passkey
      </a>
      <div>
        <strong>Congratulations!</strong> You have been selected to reserve a room in this wave.<br />
        <small>Click the 'Proceed to Passkey' button to reserve your room.</small>
      </div>
      {queueInfo.reservationInfo?.expiresAt && (
        <>
          <div className="gap" />
          <div>
            <strong>Reservation expires in</strong>
          </div>
          <div>
            <h2>
              <Countdown
                target={queueInfo.reservationInfo.expiresAt}
                onExpiry={() => window.location.reload()}
              />
            </h2>
          </div>
        </>
      )}
    </div>
  );
};
