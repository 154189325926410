import React, { useCallback, useMemo } from "react";
import { classNames } from "@conventioncatcorp/common-fe";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { MaterialIcon } from "./MaterialIcon";

import './Sidebar.scss';

interface SidebarItemProps {
  exact?: boolean;
  selected?: boolean;
  to?: string;
  type: 'header' | 'divider' | 'item';
  icon?: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  children, exact, icon, selected: overrideSelected, to, type,
}) => {
  const history = useHistory();
  const location = useLocation();

  const isItem = type === 'item';

  /**
   * Check to see if the path is an exact match. If it is, then onClick should do nothing.
   * Otherwise, we're on a subpage and onClick should redirect back to the main page.
   */
  const isExactMatch = location.pathname === to;
  const selected = overrideSelected ?? (isItem && (exact ? isExactMatch : !!to && location.pathname.startsWith(to)));

  const onClick = useCallback(() => {
    if ((selected && isExactMatch) || !isItem || !to)
      return;

    history.push(to);
  }, [isExactMatch, isItem, selected, to]);

  const content = useMemo(() => {
    if (type === 'divider')
      return null;
    
    if (type === 'header')
      return children;

    return (
      <div className='sidebar-item-content'>
        <div className='sidebar-item-content-icon'>
          <MaterialIcon name={icon ?? 'chevron_right'} />
        </div>
        <div>
          {children}
        </div>
      </div>
    )
  }, [children, icon, type]);

  return (
    <div
      className={classNames({
        'sidebar-item-selected': isItem && selected,
      }, `sidebar-${type}`)}
      onClick={onClick}
    >
      {content}
    </div>
  )
};

interface SidebarProps {
  children: React.ReactElement<SidebarItemProps>[] | React.ReactElement<SidebarItemProps>;
}

export const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  return (
    <Card className="sidebar">
      <CardBody className="sidebar-items">
        {children}
      </CardBody>
    </Card>
  );
};
