import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { EmptyState } from "../component/common";
import { useUser } from "../services";
import { ReleaseSchedule } from "../../common/models";
import { WaveInfo } from "../component/waveinfo/WaveInfo";
import { MyReservations } from "../component";

export const Homepage: React.FC = () => {
  const user = useUser();
  const [releases, setReleases] = useState<ReleaseSchedule[] | null>(null);

  useEffect(() => {
    api.getAllReleases()
      .then(r => setReleases(r));
  }, [user]);

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col lg={7} className="mb-3">
        {releases === null && (
          <EmptyState
            title="Loading..."
            details={["Just a sec, we're loading the current waves."]}
            icon="hourglass_empty"
          />
        )}
        {releases != null && releases.length === 0 ? (
          <EmptyState
            title="Sorry, there are currently no public waves available."
            details={[
              user ? 'Please keep an eye on our social media for announcements and check back later.' : 'There may be additional waves available for logged in users.'
            ]}
            icon="sentiment_dissatisfied"
          />
        ) : (
          <div>
            {releases?.map(release => (
              <div key={release.id}>
                <WaveInfo release={release} />
              </div>
            ))}
          </div>
        )}
      </Col>
      <Col lg={5}>
        <Card>
          <CardBody>
            <h3 className="mb-2">My Reservations</h3>
            <MyReservations />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
