import React, { Component } from 'react';
import { classNames } from '../../utils';

interface MaterialProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: string;
  large?: boolean;
  medium?: boolean;
  type?: 'muted' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'white';
  className?: string;
}

export class MaterialIcon extends Component<MaterialProps> {
  public override render() {
    const { name, large, medium, type, className, ...props } = this.props;
    return (
      <span
        className={
          classNames({
            large: !!large,
            medium: !!medium,
            [`text-${type}`]: !!type,
          },
          className || '',
          'material-icons')
        }
        {...props}
      >
        {name}
      </span>
    );
  }
}
