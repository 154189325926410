import { Config, RoomAvailability, RoomBlock } from "../../common/models";

interface AvailabilityProps {
  rooms: RoomBlock[];
  checkIn?: Date;
  checkOut?: Date;
}

export const fuzzyCompareDates = (date: Date, start: Date, end: Date, excludeEndDate?: boolean): boolean => {
  const availableAt = date;

  const checkInCompare = availableAt.getTime() - start.getTime();
  const checkOutCompare = availableAt.getTime() - end.getTime();

  // Fluffy match within 26 hours of the availableAt date to account for timezone differences
  if (checkInCompare >= -46800000 && checkInCompare <= 46800000)
    return true;

  return checkInCompare >= 0 && checkOutCompare < (excludeEndDate ? -93600000 : 0);
}

export const getAvailability = (availability: RoomAvailability[], start: Date, end: Date): RoomAvailability[] => {
  let foundCheckInDate = false;

  const filteredAvailability = availability.filter(availability => {
    const availableAt = availability.availableAt;

    const checkInCompare = availableAt.getTime() - start.getTime();
    const checkOutCompare = availableAt.getTime() - end.getTime();

    // If the check-in date is within 26 hours of the availableAt date, we're within the correct date range and can assume the check-in date is valid.
    if (checkInCompare >= -93600000)
      foundCheckInDate = true;

    return checkInCompare >= 0 && checkOutCompare < 0;
  });

  return foundCheckInDate ? filteredAvailability : [];
};

export const getAvailableRoomCount = (room: RoomBlock, start: Date, end: Date): number => {
  const availability = room.availability
    .filter(a => a.availableAt >= start && a.availableAt < end)
    .slice(0, -1); // Remove the last day since that's checkout

  const availableRoomCounts = availability.map(a => a.roomCount ?? 0);

  const firstNightSoldOut = availableRoomCounts[0] === 0;
  const lastNightSoldOut = availableRoomCounts[availableRoomCounts.length - 1] === 0;

  // Count the number of sold-out nights
  const soldOutNights = availableRoomCounts.filter(count => count === 0).length;

  // Check if only one night is sold out and it's either the first or last night
  if (soldOutNights > 1 || (soldOutNights === 1 && !firstNightSoldOut && !lastNightSoldOut)) {
    return 0;
  }

  // Exclude the first and last nights if they are sold out
  const remainingDays = availableRoomCounts.slice(
    firstNightSoldOut ? 1 : 0,
    lastNightSoldOut ? -1 : availableRoomCounts.length,
  );

  // Find the minimum available stock across the remaining days
  const remaining = Math.min(...remainingDays);

  return remaining === Infinity ? 0 : remaining;
};

/**
 * @description Returns a list of rooms that are available for the given check-in and check-out dates.
 * 
 * @param {AvailabilityProps} { rooms, checkIn, checkOut }
 * @param {RoomBlock[]} rooms - List of rooms to check availability for.
 * @param {Date} checkIn - Check-in date.
 * @param {Date} checkOut - Check-out date.
 * 
 * @returns {RoomBlock[]} List of rooms that are available for the given check-in and check-out dates.
 */
export const getAvailableRooms = ({ rooms, checkIn, checkOut }: AvailabilityProps): RoomBlock[] => {
  return rooms.filter(roomBlock => {
    if (!checkIn || !checkOut)
      return true;

    const availability = getAvailability(roomBlock.availability, checkIn, checkOut);
    return (
      availability.length > 0 &&
      availability.every(availability => (
        availability.available || (
          availability.roomCount && availability.roomCount > 0
        )
      ))
    );
  });
};

export const getLowestAveragePrice = (rooms: RoomBlock[], start: Date, end: Date): number => {
  const availableRooms = getAvailableRooms({ rooms, checkIn: start, checkOut: end });

  const avgPrice = availableRooms.reduce((lowestPrice, room) => {
    const availability = getAvailability(room.availability, start, end);
    
    if (availability.some(availability => !availability.available))
      return lowestPrice;

    const averagePrice = availability.reduce((total, availability) => total + availability.roomRate, 0) / availability.length;

    return averagePrice < lowestPrice ? averagePrice : lowestPrice;
  }, Infinity);

  return avgPrice === Infinity ? 0 : avgPrice;
};

export const isCoreNight = (availability: RoomAvailability, config: Config): boolean => {
  const availableAt = availability.availableAt;
  const { startAt, endAt } = config.convention;

  return fuzzyCompareDates(availableAt, startAt, endAt, true);
}
