import { useState, useCallback, useEffect, useRef } from 'react';
import { QueueInfo, ReleaseSchedule, User } from '../../common/models';
import { captureError } from './errorHandling';
import { toast } from 'react-toastify';
import { APIError } from '@conventioncatcorp/common-fe/dist/APIClient';

export * from '@conventioncatcorp/common-fe/dist/classNames';
export * from './commaFormat';
export * from './errorHandling';
export * from './removeHTMLEntities';
export * from './debounce';
export * from './roomUtils';
export * from './formatAddress';
export * from './date';

export type Fn = () => void;

export function useToggle(defaultValue: boolean): [boolean, Fn, (v: boolean) => void] {
  const [val, setVal] = useState(defaultValue);
  const toggle = useCallback(() => {
    setVal(!val);
  }, [val]);

  return [val, toggle, setVal];
}

export function renderDisplayName({ name, displayName }: { name: string, displayName: string | null }): string {
  return displayName ?? name;
}

export function renderName({ name }: User): string {
  if (name.preferred) {
    return name.preferred;
  }

  return `${name.first} ${name.last}`;
}

export function renderDateRange(start: Date, end: Date): string {
  const isSameMonth = start.getMonth() === end.getMonth();

  const startString = start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  const endString = end.toLocaleDateString('en-US', { month: isSameMonth ? undefined : 'short', day: 'numeric' });
  const year = start.getFullYear();

  return `${startString} - ${endString}, ${year}`;
}

interface cToUsdStrPrefProps {
  precision?: number;
  dropCentsIfZero?: boolean;
}

export function cToUsdStrPref(cents: number, options?: cToUsdStrPrefProps): string {
  return `$${cToUsdStr(cents, options)}`;
}

export function cToUsdStr(cents: number, options: cToUsdStrPrefProps = { precision: 2 }): string {
  const amount = (cents / 100);

  if (options.dropCentsIfZero && amount % 1 === 0) {
    return amount.toFixed(0);
  }

  return (cents / 100).toFixed(options.precision);
}

export function useHeaderTitle(title: string): void {
  const currentTitle = document.title;

  if (currentTitle !== title) {
    document.title = title;
  }
}

interface UseQueueInfoReturn {
  queueInfo: QueueInfo | null;
  update: () => void;
}

export function useQueueInfo(release: ReleaseSchedule | null): UseQueueInfoReturn {
  const [queueInfo, setQueueInfo] = useState<QueueInfo | null>(null);
  const toastId = useRef<React.ReactText | null>(null);
  const intervalId = useRef<number | null>(null);

  // Cleanup
  useEffect(() => {
    return () => {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }
      if (intervalId.current !== null) {
        clearInterval(intervalId.current);
      }
    }
  }, []);

  const update = useCallback(async () => {
    if (release === null)
      return;

    try {
      const queueInfo = await api.getQueueInfo(release.id);

      if (queueInfo.drawn) {
        try {
          const reservationInfo = await api.getReleaseReservation(queueInfo.releaseScheduleId);
          queueInfo.reservationInfo = reservationInfo;
          
          if (toastId.current !== null) {
            toast.update(toastId.current, { render: 'Congratulations! You\'re now eligible to reserve a room!', type: 'success', isLoading: false, autoClose: 5000 });
            toastId.current = null;
          }

          if (intervalId.current !== null) {
            clearInterval(intervalId.current);
            intervalId.current = null;
          }
        } catch (err) {
          if (!(err instanceof APIError) || !err.apiResponse.errors.resource || err.apiResponse.errors.resource.name !== 'Reservation') {
            captureError(err as Error);
          }

          if (intervalId.current === null) {
            intervalId.current = window.setInterval(update, 10000);
          }
        }
      } else {
        // If release is a queue
        if (release.type === 'queue') {
          if (toastId.current === null) {
            toastId.current = toast.loading('You are in the queue.\nPlease do not leave the site or you may lose your place in line.', { autoClose: false });
          }

          if (intervalId.current === null) {
            intervalId.current = window.setInterval(update, 10000);
          }
        }
      }

      setQueueInfo(queueInfo);
    } catch (err) {
      if (
        err instanceof APIError && (
          (err.apiResponse.errors.resource && err.apiResponse.errors.resource.name === 'QueueSlot') ||
          err.apiResponse.errors.authentication)
      ) {
        setQueueInfo(null);

        if (toastId.current !== null) {
          toast.dismiss(toastId.current);
          toastId.current = null;
        }

        return;
      }

      captureError(err as Error);
    }
  }, [release, toastId]);

  useEffect(() => {
    if (release === null)
      return;

    update();
  }, [update, release]);

  return { queueInfo, update };
}

export const dateToUserFormat = (date: Date): string => {
  return date.toLocaleString(undefined, { month: 'long', weekday: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
}

interface DhmsOptions {
  maxParts?: number;
}

export function secondsToDhms(_s: number, options: DhmsOptions = {}) {
  if (_s <= 0) return "0 seconds";

  const d = Math.floor(_s / (3600 * 24));
  const h = Math.floor(_s % (3600 * 24) / 3600);
  const m = Math.floor(_s % 3600 / 60);
  const s = Math.floor(_s % 60);

  let parts: string[] = [];

  if (s > 0) {
    parts.push(`${s} second${s === 1 ? '' : 's'}`);
  }

  if (m > 0) {
    parts.push(`${m} minute${m === 1 ? '' : 's'}`);
  }

  if (h > 0) {
    parts.push(`${h} hour${h === 1 ? '' : 's'}`);
  }

  if (d > 0) {
    parts.push(`${d} day${d === 1 ? '' : 's'}`);
  }

  parts = parts.reverse();

  if (options.maxParts && options.maxParts >= 1) {
    parts = parts.splice(0, options.maxParts);
  }

  return parts.join(', ');
}

export function millisecondsToDhms(_ms: number, options: DhmsOptions = {}) {
  return secondsToDhms(Math.floor(_ms / 1000), options); 
}

export function getDatesBetween(startDate: Date, stopDate: Date): Date[] {
  const dateArray: Date[] = [];
  var currentDate = startDate;

  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));

    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    currentDate = nextDate;
  }

  return dateArray;
}
