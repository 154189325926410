import React from 'react';
import { Badge } from 'reactstrap';
import { useToggle } from '../../utils';
import * as DOMPurify from 'dompurify';

import './ExpandableText.scss';

interface ExpandableTextProps {
  text: string;
  maxLength?: number;
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({ text, maxLength }) => {
  const [expand, toggleExpand] = useToggle(false);
  
  const sanitizedText = DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });

  const maximumLength = maxLength || 200;
  const doesTextExceedLength = sanitizedText.length > maximumLength;
  const textToDisplay = !doesTextExceedLength || expand ? sanitizedText : `${sanitizedText.slice(0, maximumLength)}...`;

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: textToDisplay }} />&nbsp;
      {doesTextExceedLength && (
        <Badge className="expand-text" color="secondary" onClick={toggleExpand}>
          {expand ? `Show Less` : `Read More`}
        </Badge>
      )}
    </>
  );
}
