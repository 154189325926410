import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useUser } from '../../services';
import { toast } from 'react-toastify';
import { EligibilityCheck, QueueInfo, ReleaseSchedule } from '../../../common/models';
import { classNames } from '@conventioncatcorp/common-fe/dist/classNames';
import { captureError, renderDisplayName } from '../../utils';
import { StripePayment } from '..';

interface ButtonProps {
  release: ReleaseSchedule;
  queueInfo: QueueInfo | null;
  updateQueueInfo(): void;
  block?: boolean;
}

export const WaveEntryButton: React.FC<ButtonProps> = (props) => {
  const { block, release, queueInfo, updateQueueInfo } = props;
  const [eligibility, setEligibility] = useState<EligibilityCheck | null>(null);
  const user = useUser();

  useEffect(() => {
    if (!user) {
      setEligibility(null);
      return;
    }

    api.getReleaseEligibility(release.id)
      .then(setEligibility)
      .catch((e) => {
        setEligibility(null);
        captureError(e as Error);
      })
  }, [release, user]);

  // If we're loading or logged out, don't show anything
  if (!user)
    return null;

  // If the user is already entered, show a leave button, if the entryEnd hasn't passed
  if (queueInfo && !queueInfo.drawn && (release.type === 'queue' || !release.entryEnd || release.entryEnd > new Date())) {
    return <WaveLeaveButton {...props} release={release} onChange={updateQueueInfo} />;
  }

  if (queueInfo && queueInfo.drawn) {
    const reservationInfo = queueInfo.reservationInfo;

    if (!reservationInfo) {
      return (
        <div className="wave-entry wave-entry-drawn">
          <Button block={block} color="primary" size="sm" disabled>
            Waiting for reservation link...
          </Button>
        </div>
      );
    }

    const url = reservationInfo.url;
    if (!url) {
      return <WaveReEnterButton {...props} release={release} onChange={updateQueueInfo} />;
    }

    return (
      <div className="wave-entry wave-entry-drawn">
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames({ 'btn-block': block }, 'btn btn-primary btn-sm')}
        >
          Proceed to Passkey
        </a>
      </div>
    );
  }

  if (release.type === 'draw') {
    // If the entry start date hasn't passed, show a closed button
    if (release.entryStart && release.entryStart > new Date()) {
      return (
        <div className="wave-entry wave-entry-closed">
          <Button color="secondary" size="sm" block={block} disabled>
            Entries not yet open
          </Button>
        </div>
      );
    }

    if (release.releaseStart >= new Date() && queueInfo && !queueInfo.drawn) {
      return null;
    }

    // If the release has an entry end date and it's in the past, show a closed button
    if (release.entryEnd && release.entryEnd < new Date()) {
      return (
        <div className="wave-entry wave-entry-closed">
          <Button color="secondary" size="sm" block={block} disabled>
            Entries are closed
          </Button>
        </div>
      );
    }
  }

  if (!eligibility) {
    return <div>Checking eligibility...</div>;
  }

  if (!eligibility.isEligible) {
    return (
      <div className="wave-entry wave-entry-closed">
        <Button color="secondary" size="sm" block={block} disabled>
          Not eligible to enter
        </Button>
      </div>
    );
  }

  return <WaveEnterButton {...props} release={release} onChange={updateQueueInfo} />;
};

interface SubButtonProps extends ButtonProps {
  release: ReleaseSchedule;
  onChange(): void;
}

const WaveEnterButton: React.FC<SubButtonProps> = ({ block, release, onChange }) => {
  const [isEntering, setIsEntering] = useState(false);
  const [showCardModal, setShowCardModal] = useState(false);
  const isQueue = release.type === 'queue';

  const enterWave = useCallback((token?: string) => {
    setShowCardModal(false);

    if (!token && release.requireCard) {
      setIsEntering(false);
      return;
    }

    api.joinQueue(release.id, token)
      .then(() => {
        onChange();
        toast.success("You have successfully entered this wave!");
      })
      .catch(captureError)
      .finally(() => setIsEntering(false));
  }, [release]);

  const onClickEnter = useCallback(() => {
    if (isEntering) {
      return;
    }

    setIsEntering(true);

    if (release.requireCard) {
      setShowCardModal(true);
      return;
    }

    enterWave();
  }, [release]);

  return (
    <>
      {showCardModal && (
        <StripePayment callback={enterWave} />
      )}
      <div className="wave-entry">
        <Button block={block} color="primary" size="sm" disabled={isEntering} onClick={onClickEnter}>
          {isEntering ? 'Entering...' : `Enter ${isQueue ? 'queue' : 'draw'}`}
        </Button>
      </div>
    </>
  );
};

const WaveLeaveButton: React.FC<SubButtonProps> = ({ block, release, onChange }) => {
  const isQueue = release.type === 'queue';

  const [confirmCountdown, setConfirmCountdown] = useState(isQueue ? 5 : 0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (confirmCountdown <= 0 || !showModal)
      return;

    const interval = setInterval(() => {
      setConfirmCountdown(confirmCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [confirmCountdown, showModal]);

  const onClickLeave = useCallback(() => {
    api.leaveQueue(release.id)
      .then(() => {
        setShowModal(false);
        toast.success("You have successfully withdrawn your entry from this wave.");
      })
      .finally(onChange);
  }, [release]);

  return (
    <div className="wave-entry wave-entry-entered-notdrawn">
      <Button block={block} color="danger" size="sm" onClick={() => setShowModal(true)}>
        Leave {isQueue ? 'queue' : 'draw'}
      </Button>
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader>{renderDisplayName(release)} - Withdraw Entry</ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to withdraw your entry from {renderDisplayName(release)}?
          </p>
          <br />
          <p>
            If you still want to participate in this wave, you will need to re-enter prior to the entry deadline.
          </p>
          {isQueue && (
            <>
              <br />
              <p className="text-danger">
                <strong>WARNING</strong>: If you withdraw your entry from this wave, you will lose your place in the queue.
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" disabled={confirmCountdown > 0} onClick={onClickLeave}>
            {confirmCountdown > 0 ? `WAIT... ${confirmCountdown}` : 'Withdraw Entry'}
          </Button>
          <Button color="light" outline onClick={() => setShowModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const WaveReEnterButton: React.FC<SubButtonProps> = ({ block, release, onChange }) => {
  const onClick = useCallback(() => {
    api.leaveQueue(release.id)
      .then(() => {
        api.joinQueue(release.id)
          .then(() => {
            onChange();
            toast.success("You have successfully re-entered this wave!");
          }
        );
      });

  }, [release]);

  return (
    <div className="wave-entry wave-entry-expired">
      <Button block={block} color="secondary" size="sm" onClick={onClick}>
        Re-enter wave
      </Button>
    </div>
  );
};