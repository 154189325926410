import React from 'react';
import { createContext, useReducer } from "react";
import { Config, User } from "../../common/models";

export interface NavigationElement {
  element: React.FC;
  props: any;
}

export interface GlobalState {
  config: Config | null;
  navigationElement: NavigationElement | null;
  user: User | null;
  dispatch?: React.Dispatch<GlobalStateDispatch>;
}

export enum GlobalStateAction {
  SetNavigationElement = 'setNavigationElement',
  SetConfig = 'setConfig',
  SetUser = 'setUser',
  ClearUser = 'clearUser',
}

export interface GlobalStateDispatch {
  type: GlobalStateAction;
  payload: any;
}

const defaultState: GlobalState = {
  navigationElement: null,
  config: null,
  user: null,
};

export const store = createContext<GlobalState>(defaultState);
const { Provider } = store;

export const StateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer((state: GlobalState, action: GlobalStateDispatch) => {
    const type = action.type;

    switch (type) {
      case GlobalStateAction.SetConfig:
        return { ...state, config: action.payload };

      case GlobalStateAction.SetUser:
        return { ...state, user: action.payload };

      case GlobalStateAction.ClearUser:
        return { ...state, user: null };

      case GlobalStateAction.SetNavigationElement:
        return { ...state, navigationElement: action.payload };

      default:
        return state;
    }
  }, defaultState);

  return (
    <Provider value={{ ...state, dispatch }}>{children}</Provider>
  )
}
