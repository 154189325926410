import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Row, Col } from "reactstrap";
import { MetricBlock } from "../common";
import { ReleaseSchedule } from "../../../common/models";
import { classNames } from "@conventioncatcorp/common-fe";
import { millisecondsToDhms, renderDisplayName } from "../../utils";

interface Props {
  wave?: ReleaseSchedule;
}

interface WaveStatus {
  title: string;
  value?: string;
}

export const WaveMetric: React.FC<Props> = ({ wave }) => {
  const [waveStatus, setWaveStatus] = useState<WaveStatus>({ title: 'Loading...' });

  const attendeesEntered = wave?.statistics!.entries;
  const roomsAvailable = wave?.statistics!.roomsAvailable;
  const winChance = wave ? (
    attendeesEntered === 0 ? '0' : Math.min(((roomsAvailable! / attendeesEntered!) * 100), 100).toFixed(2)
  ) : undefined;

  useEffect(() => {
    if (!wave) {
      setWaveStatus({ title: 'Loading...' });
      return;
    }

    const type = wave.type === 'queue' ? 'FCFS' : 'Random draw';

    switch (wave.status) {
      case 'queueOpen':
        setWaveStatus({
          title: `${type} entries close in`,
          value: millisecondsToDhms(wave.entryEnd!.getTime() - Date.now(), { maxParts: 2 })
        });
        break;

      case 'releaseOpen':
        setWaveStatus({
          title: `${type} release ends in`,
          value: millisecondsToDhms(wave.releaseEnd.getTime() - Date.now(), { maxParts: 2 })
        });
        break;

      case 'scheduled':
        setWaveStatus({
          title: `${wave.type === 'queue' ? 'Queue opens' : 'Release begins'} in`,
          value: millisecondsToDhms((wave.entryStart ?? wave.releaseStart).getTime() - Date.now(), { maxParts: 2 })
        });
        break;

      case 'closed':
        setWaveStatus({
          title: 'Wave closed',
          value: ''
        });
        break;
    }
  }, []);

  const entriesOverTime = wave?.statistics!.metrics.entriesOverTime;
  const roomsBookedOverTime = wave?.statistics!.metrics.roomsBookedOverTime;

  const chartData = {
    datasets: [
      {
        label: 'Entries',
        data: entriesOverTime,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Rooms booked',
        data: roomsBookedOverTime,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <Row>
      <Col xs={12} lg={6}>
        <h4 className={classNames({ 'placeholder-glow': !wave })}>
          <span className={classNames({ 'placeholder col-4': !wave })}>
            {wave ? `${renderDisplayName(wave)} (ID: ${wave.id})` : null}
          </span>
        </h4>
        <br />
        <div className={classNames({ 'placeholder-glow': !wave })}>
          <div className={classNames({ 'placeholder col-12': !wave })}>
            <Line data={chartData} />
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6}>
        <Row>
          <Col xs={12}>
            <MetricBlock
              icon="event"
              title={waveStatus.title}
              value={waveStatus.value}
            />
          </Col>
          <Col xs={12} lg={6}>
            <MetricBlock
              icon="event_available"
              title="Rooms available"
              value={roomsAvailable}
            />
          </Col>
          <Col xs={12} lg={6}>
            <MetricBlock
              icon="bed"
              title="Rooms booked"
              value={wave?.statistics!.roomsBooked}
            />
          </Col>
          <Col xs={12} lg={6}>
            <MetricBlock
              icon="people"
              title="Entries"
              value={attendeesEntered}
            />
          </Col>
          <Col xs={12} lg={6}>
            <MetricBlock
              icon="assessment"
              title="Win chance"
              value={winChance && `${winChance}%`}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
