import React, { useEffect, useState } from "react";
import { useUser } from "../services";
import { captureError } from "../utils";
import { Reservation } from "../../common/models";
import { InfoCard } from "./common";
import { MaterialIconText } from "@conventioncatcorp/common-fe";

import './MyReservations.scss';

export const MyReservations: React.FC = () => {
  const user = useUser();
  const [ reservations, setReservations ] = useState<Reservation[] | null>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    api.getMyReservations()
      .then(setReservations)
      .catch(captureError);
  }, [user]);

  if (!user) {
    return (
      <div>
        <p>Log in to view and manage your reservations.</p>
      </div>
    );
  }

  if (reservations === null) {
    return (
      <div>
        <p>Loading reservations...</p>
      </div>
    );
  }

  if (reservations.length === 0) {
    return (
      <div>
        <p>You don't have any reservations yet.</p>
      </div>
    );
  }

  const sortedReservations = reservations.sort((a, b) => a.checkIn.getTime() - b.checkIn.getTime());

  return (
    <div>
      {sortedReservations.map(reservation => (
        <ReservationListItem
          key={reservation.id}
          reservation={reservation}
        />
      ))}
    </div>
  );
};

interface ReservationListItemProps {
  reservation: Reservation;
}

const ReservationListItem: React.FC<ReservationListItemProps> = ({ reservation }) => {
  // const history = useHistory();

  if (!reservation.reservationId) {
    return (
      <InfoCard
        title={`Pending Reservation`}
      >
        <p>
          You appear to be able to reserve a room, but haven't yet!
        </p>
        <p>
          Once you've reserved a room, you'll see the details listed here.
        </p>
      </InfoCard>
    )
  }

  const subtitle = (
    <>
      <div>
        <MaterialIconText name="person">
          {reservation.guests.length} {reservation.guests.length === 1 ? 'Guest' : 'Guests'}
        </MaterialIconText>
      </div>
    </>
  );

  // const onClickManage = () => {
  //   history.push(`/reservation/${reservation.id}/${reservation.reservationId}`);
  // };

  return (
    <InfoCard
      className="reservation-info-card"
      title={`Reservation #${reservation.reservationId}`}
      subtitle={subtitle}
    >
      {/* <div className="float-right">
        <Button color="primary" size="sm" outline onClick={onClickManage}>
          View / Manage
        </Button>
      </div> */}
      <div>
        <strong>{reservation.roomBlock?.name}</strong> at <small>{reservation.hotel?.name}</small>
      </div>
      <div>
        {reservation.checkIn.toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric' })} to {reservation.checkOut.toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric' })}
      </div>
    </InfoCard>
  )
};
