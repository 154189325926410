import React, { FC, useCallback, useEffect, useState } from "react";
import { EmailTemplate } from "../../../../../common/models";
import { captureError } from "../../../../utils";
import { Card, CardBody, Table } from "reactstrap";
import { MaterialIcon } from "@conventioncatcorp/common-fe";
import { Editor } from "./Editor";

export const EmailTemplates: FC = () => {
  const [templates, setTemplates] = useState<EmailTemplate[] | null>(null);
  const [editingTemplate, setEditingTemplate] = useState<EmailTemplate | null>(null);

  const onEditClicked = useCallback((template: EmailTemplate) => {
    setEditingTemplate(template);
  }, []);

  const updateTemplates = useCallback(() => {
    api.getAllEmailTemplates()
      .then(setTemplates)
      .catch(captureError);
  }, []);

  useEffect(updateTemplates, []);

  if (editingTemplate) {
    return (
      <Editor
        template={editingTemplate}
        onBack={() => {
          updateTemplates();
          setEditingTemplate(null);
        }}
      />
    );
  }

  return (
    <div>
      <Card>
        <CardBody>
          <Table response>
            <thead>
              <tr>
                <th>Key</th>
                <th>Subject</th>
                <th>Sender Alias</th>
                <th>Options</th>
              </tr>
            </thead>
            <tbody>
              {templates?.map((template) => (
                <EmailTemplateListItem template={template} onEditClicked={onEditClicked} />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

interface EmailTemplateListItemProps {
  template: EmailTemplate;
  onEditClicked: (template: EmailTemplate) => void;
}

const EmailTemplateListItem: FC<EmailTemplateListItemProps> = ({ template, onEditClicked }) => {
  return (
    <tr>
      <td>{template.key}</td>
      <td>{template.subject}</td>
      <td>{template.from}</td>
      <td>
        <div style={{ display: 'flex' }}>
          <div onClick={() => onEditClicked(template)}>
            <MaterialIcon name="edit" />
          </div>
        </div>
      </td>
    </tr>
  )
}
