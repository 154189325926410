import { classNames } from "@conventioncatcorp/common-fe";
import React, { FC, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { EmptyState } from "../../../component/common";
import { EmailTemplates } from "./email-templates/EmailTemplates";

export const SettingsRouter: FC = () => {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 'general' })}
            onClick={() => setActiveTab('general')}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 'email_templates' })}
            onClick={() => setActiveTab('email_templates')}
          >
            Email Templates
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="general">
          <EmptyState
            title="Nothing here but us cats"
            details={["meow."]}
            icon="pets"
          />
        </TabPane>
        <TabPane tabId="email_templates">
          <EmailTemplates />
        </TabPane>
      </TabContent>
    </div>
  );
}
