import React, { FC, useEffect, useState } from "react";
import { EmailTemplate } from "../../../../../common/models";
import { addDays, captureError } from "../../../../utils";
import { useConfig } from "../../../../services";

interface Props {
  template: EmailTemplate;
}

const previewVariables = {
  user: {
    firstName: 'Test',
    lastName: 'Fox',
    preferredName: 'ThatFox',
    email: 'test.fox@example.com',
    isAttendeeRegistered: true,
    isAttendeeRegistrationPaid: true,
    remoteUserId: 1,
    admin: false,
    vip: false,
  },
  release: {
    completed: false,
    entryEnd: addDays(new Date(), 3),
    entryStart: new Date(),
    managedBy: null,
    releaseEnd: addDays(new Date(), 10),
    releaseStart: addDays(new Date(), 4),
    requireCard: false,
    requireReg: 'optional',
    returnCancelled: false,
    roomLimit: 100,
    type: 'draw',
    validityPeriod: null,
    vipOnly: false,
  },
  nextRelease: {
    completed: false,
    entryEnd: addDays(new Date(), 13),
    entryStart: new Date(),
    managedBy: null,
    releaseEnd: addDays(new Date(), 20),
    releaseStart: addDays(new Date(), 14),
    requireCard: false,
    requireReg: 'optional',
    returnCancelled: false,
    roomLimit: 100,
    type: 'draw',
    validityPeriod: null,
    vipOnly: false,
  },
  reservation: {
    reservationId: 'ABCDEFG-1234',
    roomBlock: {
      name: 'Vista Queen Room'
    },
    hotel: {
      name: 'Test Hotel',
      isMainHotel: true,
      description: 'This could be plain text, or rich text (HTML).',
      addressLine1: '100 Main St',
      addressLine2: null,
      addressCity: 'Denver',
      addressState: 'Colorado',
      addressCountry: 'UNITED STATES',
      addressZipcode: '80808',
      phoneNumber: '1 (555) 555-5555',
      website: 'https://concat.app',
    },
    guests: [
      {
        firstName: 'Test',
        lastName: 'Fox',
        phone: '1 (555) 555-5555',
        email: 'test.fox@example.com',
        addressLine1: '100 Main St',
        addressLine2: 'Suite 100',
        addressCity: 'Denver',
        addressState: 'Colorado',
        addressCountry: 'UNITED STATES',
        addressZipcode: '80808',
        specialRequest: 'Extra towels'
      },
      {
        firstName: 'Test',
        lastName: 'Cat',
        phone: '1 (555) 555-5555',
        email: 'test.cat@example.com',
        addressLine1: '100 Main St',
        addressLine2: 'Suite 100',
        addressCity: 'Denver',
        addressState: 'Colorado',
        addressCountry: 'UNITED STATES',
        addressZipcode: '80808',
        specialRequest: null
      }
    ],
    cardType: 'Mastercard',
    cardLast4: '1234',
    cardholderName: 'Test Fox',
    cancellationTerms: '<p>Cancellation required at least 48 hours prior to arrival. This text will be different, or may be entirely omitted, depending on the hotel.</p>',
    checkInDate: addDays(new Date(), 60),
    checkOutDate: addDays(new Date(), 64),
  },
  editUrl: 'https://reservations.bookingbun.app/res/modify/ABCEDG-1234',
  isWaitlisted: false,
  bridgeId: 'BRD-ABCDEF-1234',
  bridgeUrl: 'https://reservations.bookingbun.app/res/bridge/BRD-ABCDEF-1234',
};

export const Preview: FC<Props> = ({ template }) => {
  const [isLoadingPreview, setIsLoadingPreview] = useState(true);
  const [previewHtml, setPreviewHtml] = useState<string | null>(null);
  const { convention } = useConfig()!;

  useEffect(() => {
    api.getEmailTemplatePreview({
      template,
      variables: {
        convention,
        ...previewVariables,
      },
    })
    .then(preview => setPreviewHtml(preview.body))
    .catch((e) => {
      setPreviewHtml(null);
      captureError(e);
    })
    .finally(() => setIsLoadingPreview(false));
  }, [convention, template]);

  if (isLoadingPreview) {
    return (
      <div>
        <i>Loading... This may take a moment.</i>
      </div>
    )
  }

  if (!previewHtml) {
    return (
      <div>
        <strong>ERROR</strong>: Unable to render template preview.
      </div>
    );
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
  )
}
