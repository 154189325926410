import React, { useCallback } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useConfig, useUser } from "../services";
import { LoginContext } from "./LoginContext";
import { useHistory, useLocation } from "react-router-dom";
import { MaterialIcon, RichMarkdownIt } from "./common";

import './Header.scss';

export const Header: React.FC = () => {
  const { convention } = useConfig()!;
  const location = useLocation();
  const history = useHistory();
  const user = useUser();

  const getDates = useCallback(() => {
    const isSameMonth = convention.startAt.getMonth() === convention.endAt.getMonth();

    const startString = convention.startAt.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const endString = convention.endAt.toLocaleDateString('en-US', { month: isSameMonth ? undefined : 'short', day: 'numeric' });
    const year = convention.startAt.getFullYear();

    return `${startString} - ${endString}, ${year}`;
  }, [convention]);

  const onHeaderClick = useCallback(() => {
    history.push('/');
  }, []);

  const shouldHideAnnouncement = location.pathname.startsWith('/organizer');
  const showHero = !shouldHideAnnouncement && (convention.heroImage || convention.systemAnnouncement);

  const shouldShowMaintenance = convention.adminOnlyMode && (!user || !user.hasPermission('reservation:admin'));

  return (
    <>
      <header className="main-header">
        <div className="main-header-logo">
          {convention.logo ? (
            <img src={convention.logo} alt={`${convention.shortName} Logo`} />
          ) : (
            <MaterialIcon name="bedroom_parent" />
          )}
        </div>
        <div className="main-header-info" onClick={onHeaderClick}>
          <div className="main-header-info__eventinfo">
            <h1>{convention.shortName} Hotel Booking</h1>
            <div className="dates">{getDates()}</div>
          </div>
        </div>
        <div className="main-header-userinfo">
          <LoginContext />
        </div>
      </header>
      {shouldShowMaintenance && (
        <div className="maintenance-banner">
          🚧 Maintenance mode is active. Only the convention staff can login at this time. Please check back later.
        </div>
      )}
      {showHero && (
        <div
          className="hero"
          style={convention.heroImage ? { backgroundImage: `url(${convention.heroImage})` } : {}}
        >
          <Row className="hero-content">
            <Col lg={7}>
              {convention.systemAnnouncement ? (
                <Card className="hero-announcement">
                  <CardBody>
                    <RichMarkdownIt source={convention.systemAnnouncement} />
                  </CardBody>
                </Card>
              ) : (
                <div className="hero-filler" />
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};