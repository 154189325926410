import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";
import { CriteriaList } from "../CriteriaList";
import { EligibilityCheck, ReleaseSchedule } from "../../../common/models";
import { captureError, renderDateRange, useQueueInfo } from "../../utils";
import { useUser } from "../../services";
import { Link } from "react-router-dom";
import { WaveEntryButton } from "./WaveEntryButton";
import { WaveInfoEntryStatus } from "./WaveInfoEntryStatus";
import { MaterialIcon } from "../common";

interface Props {
  release: ReleaseSchedule;
}

export const WaveInfo: React.FC<Props> = ({ release }) => {
  const user = useUser();
  const { queueInfo, update: updateQueueInfo } = useQueueInfo(release);

  return (
    <Card className="mb-3">
      <CardBody>
        <Row>
          <Col md={6} xs={12}>
            <h4>{release.displayName ?? release.name}</h4>
            <div>
              <small>
                <strong>Draw Period</strong>: {renderDateRange(release.releaseStart, release.releaseEnd)}
              </small>
            </div>
            {release.vipOnly && (
              <div>
                <small>
                  <strong>This is a VIP-only wave, and is not open to the general public.</strong><br />
                  Access is restricted to users with the following roles: {release.allowedRoles?.map(r => r.displayName ? `${r.displayName} (${r.roleName})` : r.roleName).join(', ')}
                </small>
              </div>
            )}
            <div className="mt-2">
              <WaveBadges release={release} />
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div>
              {user ? (
                <div>
                  <div className="mb-2">
                    <WaveInfoEntryStatus queueInfo={queueInfo} release={release} updateQueueInfo={updateQueueInfo} short />
                  </div>
                  <div className="mb-2">
                    <WaveEntryButton queueInfo={queueInfo} release={release} updateQueueInfo={updateQueueInfo} block />
                  </div>
                </div>
              ) : (
                <div>
                  <h6>
                    <strong>Please login to participate in this wave, or view your participation status.</strong>
                  </h6>
                </div>
              )}
              {release.showRoomAvailability && (
                <Link to={`/draw/${release.id}/search`} className="btn btn-outline-light btn-sm btn-block">
                  Preview available rooms
                </Link>
              )}
            </div>
          </Col>
        </Row>
        <WaveInfoEntryStatus queueInfo={queueInfo} release={release} updateQueueInfo={updateQueueInfo} />
        <hr />
        {/* <div>
          <h5>Description</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            aliquet, nisl nec ultricies lacinia, nunc nisl aliquam nisl, nec
            aliquam nisl nunc vel nisl. Sed aliquet, nisl nec ultricies
            lacinia, nunc nisl aliquam nisl, nec aliquam nisl nunc vel nisl.
          </p>
        </div>
        <hr /> */}
        {!queueInfo && (
          <div>
            <h5>Entry criteria</h5>
            <WaveCriteria release={release} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const WaveCriteria: React.FC<Props> = ({ release }) => {
  const [criteria, setCriteria] = useState<EligibilityCheck | null>(null);

  useEffect(() => {
    api.getReleaseEligibility(release.id)
      .then(setCriteria)
      .catch((e) => {
        setCriteria(null);
        captureError(e as Error);
      })
  }, [release]);

  if (!criteria) {
    return <div>Checking your eligibility...</div>;
  }

  return (
    <CriteriaList
      criteria={criteria.criteria}
      messages={{
        allCriteriaMet: "You currently meet all of the entry criteria, and are eligible to enter this wave.",
        someCriteriaMet: "You don't meet all of the entry criteria, and are not eligible to enter this wave at this time.",
      }}
    />
  )
};

const WaveBadges: React.FC<Props> = ({ release }) => {
  const badges: JSX.Element[] = [];

  if (release.type === 'draw') {
    badges.push(
      <Badge color="secondary">
        <MaterialIcon name="casino" />
        Random Draw
      </Badge>
    );

    if (
      release.entryStart != null && release.entryStart.getTime() > Date.now()
    ) {
      badges.push(
        <Badge color="warning">
          <MaterialIcon name="schedule" />
          Entries Open {release.entryStart.toLocaleString('en-us', { timeZoneName: 'short' })}
        </Badge>
      )
    } else if (
      release.entryEnd != null && release.entryEnd.getTime() > Date.now()
    ) {
      badges.push(
        <Badge color="success">
          <MaterialIcon name="check" />
          Accepting Entries
        </Badge>
      );
    } else if (
      release.entryEnd != null && release.releaseStart.getTime() > Date.now()
    ) {
      badges.push(
        <Badge color="warning">
          <MaterialIcon name="timelapse" />
          Entries Closed &mdash; Draw Pending
        </Badge>
      )
    } else {
      badges.push(
        <Badge color="info">
          <MaterialIcon name="check_circle" />
          Draw Complete
        </Badge>
      )
    }
  } else {
    badges.push(
      <Badge color="secondary">
        <MaterialIcon name="queue" />
        First Come, First Serve
      </Badge>
    );

    if (release.releaseStart.getTime() <= Date.now() && release.releaseEnd.getTime() > Date.now()) {
      badges.push(
        <Badge color="success">
          <MaterialIcon name="check" />
          Accepting Entries
        </Badge>
      );
    } else {
      badges.push(
        <Badge color="danger">
          <MaterialIcon name="schedule" />
          Not Accepting Entries
        </Badge>
      )
    }
  }

  if (release.vipOnly) {
    badges.push(
      <Badge color="warning">
        <MaterialIcon name="auto_awesome" />
        VIP Only
      </Badge>
    );
  }

  return (
    <div>
      {badges.map((badge, i) => (
        <div key={i} className="mr-2 mb-2">
          {badge}
        </div>
      ))}
    </div>
  );
};