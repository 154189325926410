import React from "react";
import { Redirect, Route } from "react-router";
import { useUser } from "../../services";
import { OrganizerDashboard } from "./Dashboard";
import { Sidebar, SidebarItem } from "../../component/common";
import { Col, Row } from "reactstrap";
import { Router as HotelsRouter } from "./hotels/Router";
import { Router as RoomsRouter } from "./room-blocks/Router";
import { SettingsRouter } from "./settings/Router";

export const OrganizerRouter: React.FC = () => {
  const user = useUser();

  if (!user || !user.hasPermission('reservation:admin'))
    return <Redirect to="/" />;

  return (
    <Row>
      <Col xs={12} md={4} lg={3}>
        <OrganizerSidebar />
      </Col>
      <Col xs={12} md={8} lg={9}>
        <Route path="/organizer" exact component={OrganizerDashboard} />
        <Route path="/organizer/hotels" component={HotelsRouter} />
        <Route path="/organizer/room-blocks" component={RoomsRouter} />
        <Route path="/organizer/settings" component={SettingsRouter} />
      </Col>
    </Row>
  )
};

const OrganizerSidebar: React.FC = () => {
  return (
    <Sidebar>
      <SidebarItem type="header">Overview</SidebarItem>
      <SidebarItem type="item" to="/organizer" icon="dashboard" exact>Dashboard</SidebarItem>
      <SidebarItem type="divider" />
      <SidebarItem type="header">Inventory Management</SidebarItem>
      <SidebarItem type="item" to="/organizer/hotels" icon="hotel">Hotels</SidebarItem>
      <SidebarItem type="item" to="/organizer/room-blocks" icon="room_preferences">Room Blocks</SidebarItem>
      <SidebarItem type="divider" />
      <SidebarItem type="header">Reservation Management</SidebarItem>
      <SidebarItem type="item" to="/organizer/reservations" icon="confirmation_number">Reservations</SidebarItem>
      <SidebarItem type="item" to="/organizer/waves" icon="today">Wave Scheduler</SidebarItem>
      <SidebarItem type="divider" />
      <SidebarItem type="header">Settings</SidebarItem>
      <SidebarItem type="item" to="/organizer/settings" icon="settings">System Settings</SidebarItem>
      <SidebarItem type="item" to="/organizer/integrations" icon="integration_instructions">Integrations</SidebarItem>
    </Sidebar>
  )
};
