import React from "react";
import { Alert } from "reactstrap";
import { QueueInfo, ReleaseSchedule } from "../../../common/models";
import { dateToUserFormat } from "../../utils";
import { useUser } from "../../services";
import { WaveInfoWin } from "./WaveInfoWin";

export interface WaveInfoEntryStatusProps {
  queueInfo: QueueInfo | null;
  release: ReleaseSchedule;
  short?: boolean;
  updateQueueInfo(): void;
}

export const WaveInfoEntryStatus: React.FC<WaveInfoEntryStatusProps> = (props) => {
  const { release, queueInfo, short, updateQueueInfo } = props;

  if (queueInfo?.drawn) {
    return (
      <WaveInfoWin
        queueInfo={queueInfo}
        release={release}
        short={short}
        updateQueueInfo={updateQueueInfo}
      />
    );
  }
  
  return release.type == 'draw' ? <RandomDrawStatus {...props} /> : <FCFSStatus {...props} />;
};

const RandomDrawStatus: React.FC<WaveInfoEntryStatusProps> = ({ queueInfo, release, short }) => {
  const user = useUser();
  const drawDate = dateToUserFormat(release.releaseStart);

  // Has the entry period started?
  if (release.entryStart && release.entryStart > new Date()) {
    if (short) {
      return <div>Entries for this wave will open on <strong>{dateToUserFormat(release.entryStart)}</strong>.</div>;
    }

    return null;
  }

  // Has the entry period passed?
  if (release.entryEnd && release.entryEnd < new Date() && release.releaseStart >= new Date()) {
    if (short) {
      return <div>Entries for this wave have closed. The draw will take place on <strong>{drawDate}</strong>.</div>;
    }

    return null;
  }

  if (release.releaseStart <= new Date()) {
    if (short) {
      if (user && queueInfo && !queueInfo.drawn) {
        return <div>You were not selected in this wave.</div>;
      }

      return <div>This wave has concluded.</div>;
    }

    return null;
  }

  // If the user is already entered
  if (queueInfo) {
    if (short) {
      return <div>You have entered this wave. The draw will take place on <strong>{drawDate}</strong>.</div>;
    }

    return (
      <>
        <hr />
        <div className="mb-1">
          <strong>You have entered this wave.</strong> The draw will take place on <strong>{drawDate}</strong>.
        </div>
        <div>
          You will be notified by email with the results of the draw, and if you are successful, you will be provided with a unique link to reserve your room.
        </div>
      </>
    );
  }

  // Otherwise, show the entry instructions
  if (short) {
    return (
      <>
        <div>You have not yet entered this wave.</div>
        {release.requireCard && (
          <div>
            <strong>A valid credit or debit card is required to enter this draw.</strong>
          </div>
        )}
      </>
    );
  }

  const entryEnd = dateToUserFormat(release.entryEnd!);

  return (
    <>
      <hr />
      {release.requireCard && (
        <>
          <div>
            <Alert color="info">
              <div className="mb-1">
                <strong>A valid credit or debit card is required to enter this draw.</strong> <i>(Prepaid debit cards are <strong>NOT</strong> accepted.)</i>
              </div>
              <div className="mb-1">
                <strong>You will not be charged</strong> for entering the draw. We do a test authorization of $1.00 to verify your card is valid to prevent abuse of the system.
              </div>
            </Alert>
          </div>
          <hr />
        </>
      )}
      <div className="mb-1">
        {user ? <strong>You have not yet entered this wave.</strong> : <strong>Please login to participate in this wave, or view your participation status.</strong> }
      </div>
      <div className="mb-1">
        You can find the eligibility criteria for this wave below. If you meet the criteria, you can enter this wave by clicking the "<strong>Enter draw</strong>" button.
      </div>
      <div>
        Entries for this wave will no longer be accepted after <strong>{entryEnd}</strong>, and will be drawn on <strong>{drawDate}</strong>.
        You will be notified by email with the results of the draw, and if you are successful, you will be provided with a unique link to reserve your room.
      </div>
    </>
  );
};

const FCFSStatus: React.FC<WaveInfoEntryStatusProps> = ({ release, queueInfo, short }) => {
  const endDate = dateToUserFormat(release.releaseEnd);
  const hasValidityPeriod = release.validityPeriod && release.validityPeriod > 0;

  if (queueInfo) {
    if (short) {
      return (
        <>
          <div>You are in the queue for this wave.</div>
          {hasValidityPeriod ? (
            <div>
              <strong>Please do not leave the site</strong>, or you may lose your place in the queue.
            </div>
          ) : (
            <div>
              Your link will appear on this page once you are eligible to reserve a room. You will also receive an email notification when you are eligible.
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <hr />
        <Alert color="warning">
          <div className="mb-1">
            You are in the queue for this wave, and will be notified once you are eligible to reserve a room.
          </div>
          <div className="mb-1">
            This draw uses a first-come, first-served queue system with a reservation window.
            {hasValidityPeriod ? (
              ' Once you are eligible to reserve a room, you will have a limited amount of time to complete your reservation.'
            ) : (
              ` Once you are eligible to reserve a room, you'll have until ${endDate} to complete your reservation.`
            )}
          </div>
          {hasValidityPeriod && (
            <div>
              <strong>Please do not leave the site</strong>, or you may lose your place in the queue.
            </div>
          )}
        </Alert>
      </>
    );
  }

  // Otherwise, show the entry instructions
  if (short) {
    return <div>You are not yet in the queue for this wave.</div>;
  }

  return (
    <>
      <hr />
      <div className="mb-1">
        <strong>You are not yet in the queue for this wave.</strong> If you meet the criteria for this wave, you can join the queue for this wave by clicking the "<strong>Join queue</strong>" button.
      </div>
      {hasValidityPeriod && (
        <>
          <div className="mb-1">
            This draw uses a first-come, first-served queue system with a reservation window. Once you are eligible to reserve a room, you will have a limited amount of time to complete your reservation.
          </div>
          <div>
            Once you have entered the queue, <strong>please do not leave the site</strong>, or you may lose your place in the queue.
          </div>
        </>
      )}
    </>
  );
};
