import React, { useCallback } from "react";
import { Card, CardBody } from "reactstrap";
import { MaterialIcon } from "./MaterialIcon";
import { useHistory } from "react-router";
import { classNames } from "@conventioncatcorp/common-fe";

import './InfoCard.scss';

interface InfoCardProps {
  title: string;
  subtitle?: string | React.ReactNode;
  loading?: boolean;
  editLinkTo?: string;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ editLinkTo, subtitle, loading, title, children, className }) => {
  const history = useHistory();

  const onClickEdit = useCallback(() => {
    if (!editLinkTo)
      return;

    history.push(editLinkTo);
  }, [editLinkTo]);

  return (
    <Card className={classNames({}, "infocard", className || '')}>
      <CardBody>
        <div className="infocard-title-area">
          {editLinkTo && (
            <div className="float-right infocard-edit-icon" onClick={onClickEdit}>
              <MaterialIcon name="edit" />
            </div>
          )}
          <div className={classNames({ 'placeholder-glow': loading }, 'infocard-title-section')}>
            <div className='infocard-title'>
              <strong className={classNames({ 'placeholder': loading })}>
                {title}
              </strong>
            </div>
            {(subtitle || loading) && (
              <div className='infocard-subtitle'>
                <small className={classNames({ 'placeholder': loading })}>
                  {subtitle}
                </small>
              </div>
            )}
          </div>
        </div>
        {children}
        {loading && (
          <div className="placeholder-glow">
            <div className="placeholder col-12" />
            <div className="placeholder col-8" />
          </div> 
        )}
      </CardBody>
    </Card>
  )
};
