import { classNames } from "@conventioncatcorp/common-fe";
import React, { useCallback, useEffect, useState } from "react";
import { MaterialIcon } from "./MaterialIcon";

import './IncrementPicker.scss';

interface Props {
  defaultValue: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
}

export const IncrementPicker: React.FC<Props> = ({
  defaultValue,
  min = 0,
  max = 100,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const increment = useCallback(() => {
    if (value < max) {
      setValue(value + 1);
    }
  }, [value, max, onChange]);

  const decrement = useCallback(() => {
    if (value > min) {
      setValue(value - 1);
    }
  }, [value, min, onChange]);
  
  useEffect(() => onChange(value), [value, onChange]);

  const canDecrement = value > min;
  const canIncrement = value < max;

  return (
    <div className="increment-picker">
      <div
        className={
          classNames(
            { "increment-picker__button-disabled": !canDecrement },
            "increment-picker__button increment-picker__button-decrement"
          )}
        onClick={decrement}
      >
        <MaterialIcon name="remove_circle" />
      </div>
      <div className="increment-picker__value">
        {value}
      </div>
      <div
        className={
          classNames(
            { "increment-picker__button-disabled": !canIncrement },
            "increment-picker__button increment-picker__button-increment"
          )}
        onClick={increment}
      >
        <MaterialIcon name="add_circle"/>
      </div>
    </div>
  );
};