import React, { useCallback, useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { captureError } from "../utils";
import { useConfig } from "../services";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";

interface StripePaymentProps {
  callback: (token?: string) => void;
}

export const StripePayment: React.FC<StripePaymentProps> = ({ callback }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [intentSecret, setIntentSecret] = useState<string | null>(null);

  const config = useConfig();

  useEffect(() => {
    if (!config) {
      return;
    }

    // Fetch the payment intent secret
    api.getStripeIntent()
      .then(({ clientSecret, connectAccountId }) => {
        setIntentSecret(clientSecret);
        loadStripe(config?.payments.stripe_pk, {
          stripeAccount: connectAccountId,
        })
          .then(stripe => {
            setStripe(stripe);
          })
          .catch(captureError);
      })
      .catch(captureError);
  }, [config]);

  const closeModal = useCallback(() => {
    callback();
  }, [callback]);

  const isReady = !!stripe && !!intentSecret;

  return (
    <Modal isOpen toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Card Verification</ModalHeader>
      <ModalBody>
        <p>
          We'll do a test authorization of $1.00 to verify your credit or debit card information and prevent abuse of the system. You will not be charged for entering the draw.
        </p>
        <hr />
        {isReady ? (
          <Elements options={{ appearance: { theme: 'stripe' }, clientSecret: intentSecret }} stripe={stripe}>
            <StripePaymentForm callback={callback} />
          </Elements>
        ) : (
          <p>Loading Stripe, one moment...</p>
        )}
      </ModalBody>
    </Modal>
  )
};

const StripePaymentForm: React.FC<StripePaymentProps> = ({ callback }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!stripe || !elements) {
      return;
    }

    const paymentIntent = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (paymentIntent.error) {
      setError(paymentIntent.error.message ?? 'Your card was declined by the payment processor.');
      setIsSubmitting(false);
      return;
    }
    
    callback(paymentIntent.paymentIntent.id);
  }, [stripe]);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {error && (
        <div>
          <Alert color='danger'>
            <p>We encountered an error while trying to verify your card:</p>
            <p>{error}</p>
          </Alert>
        </div>
      )}
      <PaymentElement options={{ layout: 'accordion' }} />
      <Button block color='primary' type='submit' className="mt-2" disabled={isSubmitting}>
        Verify Card
      </Button>
    </form>
  )
}