import React, { useEffect, useState } from "react";
import { EmptyState } from "../component/common";
import { useConfig } from "../services";
import { captureError } from "../utils";
import { Button } from "reactstrap";
import { showReportDialog } from "@conventioncatcorp/common-fe";
import { Redirect } from "react-router";
import { LoginMessage, LoginStatus } from "../component/LoginModal";

export const LoginAdBlock: React.FC = () => {
  const [redirectUrl, setRedirectUrl] = useState<string | null>('');
  const [errorId, setErrorId] = useState<string | null>(null);
  const config = useConfig();

  if (!config) {
    return null;
  }

  useEffect(() => {
    api
      .getOAuthUrl()
      .then(({ url }) => {
        setRedirectUrl(url);
      })
      .catch((error) => {
        const eId = captureError(error);
        setErrorId(eId ?? null);
        setRedirectUrl(null);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!redirectUrl) return;

      window.location.replace(redirectUrl);
    }, 2000);
  });

  if (redirectUrl === null) {
    return (
      <EmptyState
        title="Error"
        details={[
          "We're sorry, but an unexpected error is preventing the application from loading. This is likely a temporary issue, so please try again in a few minutes.",
          errorId ? (
            <Button color="primary" outline onClick={() => showReportDialog(errorId)}>
              Report Error
            </Button>
          ) : <></>
        ]}
        icon="warning"
      />
    );
  }

  return (
    <EmptyState
      title={`Redirecting you to ${config.convention.longName}...`}
      details={[
        "We're redirecting you to the registration site to login.",
      ]}
      icon="warning"
    />
  );
};

export const LoginPage: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');

  if (!error) {
    return <Redirect to="/" />;
  }

  let status: LoginStatus;
  switch (error) {
    case 'failed':
      default:
      status = LoginStatus.Failed;
      break;

    case 'underage':
      status = LoginStatus.UnderageUser;
      break;
  }

  return <LoginMessage status={status} />
};
