import React, { useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router";
import { GlobalStateAction, useDispatch, useGlobalState } from "../services";

export enum LoginStatus {
  Pending,
  Success,
  Failed,
  UnderageUser,
  PopupError,
};

interface Props {
  onClose: () => void;
}

export const LoginModal: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState(LoginStatus.Pending);

  useEffect(() => {
    let popupMonitor: NodeJS.Timer;
    let popup: Window | null;
    let listener: (event: MessageEvent) => void;

    api.getOAuthUrl().then(({ url }) => {
      popup = window.open(
        url,
        '_blank',
        'scrollbars=yes,resize=no,width=500,height=700',
      );

      if (!popup) {
        history.push('/auth/continue');
        return;
      }

      popupMonitor = setInterval(() => {
        if (popup?.closed) {
          setStatus(LoginStatus.Failed);
          clearInterval(popupMonitor);
        }
      }, 1000);

      listener = async (event: MessageEvent) => {
        if (event.origin !== window.location.origin) {
          return;
        }

        clearInterval(popupMonitor);

        const { ok, error } = JSON.parse(event.data) as { ok: boolean, error: string | null };

        if (ok) {
          api.getActiveUser()
            .then(user => {
              setStatus(LoginStatus.Success);
              setTimeout(() => {
                dispatch({ type: GlobalStateAction.SetUser, payload: user });
              }, 1000);
            })
            .catch(() => setStatus(LoginStatus.Failed));
        } else if (error === 'underage') {
          setStatus(LoginStatus.UnderageUser);
        } else {
          setStatus(LoginStatus.Failed);
        }
      };

      window.addEventListener('message', listener, false);
    });

    return () => {
      clearInterval(popupMonitor);
      popup?.close();
      window.removeEventListener('message', listener, false);
    };
  }, []);

  useEffect(() => {
    if (status === LoginStatus.Pending)
      return;

    setTimeout(() => {
      onClose();
    }, status === LoginStatus.Success ? 2000 : 5000);
  }, [status, onClose]);

  if (status === LoginStatus.PopupError) {
    return null;
  }

  return (
    <Modal isOpen className="login-modal">
      <ModalBody className="login-modal__body">
        <LoginMessage status={status} closeWindow={onClose} />
      </ModalBody>
    </Modal>
  );
};

interface LoginMessageProps {
  status: LoginStatus;
  closeWindow?: () => void;
}

export const LoginMessage: React.FC<LoginMessageProps> = ({ closeWindow, status }) => {
  const minimumAge = useGlobalState(cb => cb.config?.convention.minimumAge);

  switch (status) {
    case LoginStatus.Pending:
      return (
        <>
          <h4 className="mb-2">Waiting for login result...</h4>
          <p>Don't see the pop-up window? Check to make sure you've disabled AdBlock.</p>
          <br />
          <Button color="light" onClick={closeWindow}>Close</Button>
        </>
      );

    case LoginStatus.Success:
      return (
        <>
          <h4 className="mb-2">Login successful!</h4>
          <p>Just a moment while we sign you in...</p>
        </>
      );

    case LoginStatus.UnderageUser:
      return (
        <>
          <h4 className="mb-2">Login failed</h4>
          <p>Sorry, we're unable to log you in as this event requires attendees booking rooms to be at least {minimumAge} years of age.</p>
          <br />
          <p>Please contact the event if you have any questions.</p>
        </>
      );

    default:
      return (
        <>
          <h4 className="mb-2">Login failed</h4>
          <p>Sorry, we were unable to log you in. Please try again later.</p>
        </>
      );
  }
};
