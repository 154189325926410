import React, { useCallback } from 'react';
import { Input, InputProps } from "reactstrap";

interface DateInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (date: Date) => void;
}

export const DateInput: React.FC<DateInputProps> = ({ onChange, ...props }) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange)
      return;

    const date = new Date(e.target.value);
    onChange(date);
  }, [onChange]);

  return (
    <Input
      type="date"
      onChange={handleChange}
      {...props}
    />
  )
};
