import React, { useEffect, useState } from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import { RoomBlock } from '../../../../common/models';
import { useHistory, useParams } from 'react-router-dom';
import { isResourceError, captureError, getAvailableRoomCount, isCoreNight } from '../../../utils';
import { Collapse, EmptyState, ExpandableText, MaterialIcon } from '../../../component/common';
import { useConfig } from '../../../services';
import { HotelRoomAvailability } from '../../../component/HotelRoomAvailability';

import './View.scss';

interface Params {
  id: string;
}

export const View: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const config = useConfig();
  const [room, setRoom] = useState<RoomBlock | null>(null);

  useEffect(() => {
    const parsedId = parseInt(id);

    if (isNaN(parsedId)) {
      history.push('/organizer/room-blocks');
      return;
    }

    api
      .getRoomBlock(parseInt(id))
      .then(setRoom)
      .catch((error: Error) => {
        if (isResourceError(error, 'Hotel')) {
          history.push('/organizer/room-blocks');
          return;
        }

        captureError(error);
      });
  }, []);

  if (!room) {
    return (
      <EmptyState
        title="Loading..."
        details={["Just a sec while we find that room block..."]}
        icon="hourglass_empty"
      />
    )
  }

  const hotel = room.hotel!;

  const isSoldOut = getAvailableRoomCount(
    room,
    config!.convention.startAt,
    config!.convention.endAt,
  ) === 0;

  return (
    <div>
      <Card className="mb-3">
        <CardBody className="align-center card-item-header">
          <div>
            <h4 className="mb-1">
              Room Block #{room.id} - {room.name} <small>@ {hotel.name}</small>
            </h4>
            <div className="badge-list">
              {room.visibility ?
                <Badge color="success">Publicly Visible</Badge> :
                <Badge color="warning">Hidden from Attendees</Badge>}
              {hotel.isMainHotel && <Badge color="primary">In Main Hotel</Badge>}
              {isSoldOut && <Badge color="danger">Sold Out</Badge>}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row>
        <Col xs={12} lg={4}>
          <Card>
            <CardBody>
              <h5>Ext. Room ID</h5>
              <p>{room.extBlockId ?? 'N/A'}</p>

              <h5>Max Persons</h5>
              <p>{room.maxPersons}</p>

              <h5>Description</h5>
              <div className="mb-3">
                <ExpandableText
                  text={room.description ?? 'No description available.'}
                  maxLength={128}
                />
              </div>

              <h5>Additional Info</h5>
              <div className="mb-3">
                <ExpandableText
                  text={room.additionalInfo ?? 'No additional information.'}
                  maxLength={128}
                />
              </div>
              
              <hr />
              <div>
                <small>
                  <strong>Created</strong> {room.createdAt!.toLocaleString()}
                </small>
              </div>
              <div>
                <small>
                  <strong>Last updated</strong> {room.updatedAt!.toLocaleString()}
                </small>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} lg={8}>
          <Collapse
            title="Availability"
            countBadge={room.availability.length}
            defaultOpen
            warningBanner={(
              <>Availability is <strong>read only</strong> as this room block is managed by a third-party integration.</>
            )}
            footer={(
              <span className="text-with-icon">
                <MaterialIcon name="star" type="warning" /> <small>Core Room Night</small>
              </span>
            )}
          >
            <div className="room-availability">
              {room.availability.map((availability, i) => (
                <HotelRoomAvailability
                  key={i}
                  availability={availability}
                  showRoomCount
                  isCoreNight={isCoreNight(availability, config!)}
                />
              ))}
            </div>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
}