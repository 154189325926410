import { APIClient as BaseClient } from '@conventioncatcorp/common-fe/dist/APIClient';
import { init } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import React from 'react';
import { render } from 'react-dom';
import { APIClient } from './APIClient';
import { captureError } from './utils';
import { BookingBunApp } from './BookingBunApp';
import { StateProvider } from './services/RootReducer';

import './style/main.scss';
import 'react-toastify/dist/ReactToastify.css';

declare global {
  let api: APIClient;
  interface Window {
    api: APIClient;
    reactHistory: (path: string) => void;
  }
  const VERSION: string;
  const SENTRY_DSN: string;
  const ENVIRONMENT: string;
}

const httpBase = new BaseClient();
window.api = new APIClient(new BaseClient());

init(httpBase, captureError);

render(
  <StateProvider>
    <BookingBunApp />
  </StateProvider>,
  document.getElementById('app'),
);
