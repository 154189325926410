import React from 'react';
import { InlineHelp } from '@conventioncatcorp/common-fe/dist/components/InlineHelp';
import { Badge } from 'reactstrap';
import { Hotel, HotelFee, HotelFeeType, RoomAvailability, RoomBlock } from '../../common/models';
import { HotelRoomAvailability } from './HotelRoomAvailability';
import { cToUsdStrPref } from '../utils';
import { SearchFilter } from './HotelRoomSearch';
import { MaterialIcon } from './common';

import './HotelRoom.scss';

interface HotelRoomProps {
  hotel: Hotel;
  search: SearchFilter | null;
  room: RoomBlock;
}

export const HotelRoom: React.FC<HotelRoomProps> = ({ hotel, room, search }) => {
  // Sort fees by if they are applied per night or not
  const fees = hotel.fees?.sort((a, b) => {
    if (a.applyPerNight === b.applyPerNight)
      return 0;

    if (a.applyPerNight)
      return -1;

    return 1;
  });
  const bedType = getRoomTypeFromName(room);

  const roomAvailability = room.availability?.filter(availability => 
    {
      if (!search)
        return true;

      if (search.checkIn && availability.availableAt.getTime() < search.checkIn.getTime())
        return false;

      if (search.checkOut && availability.availableAt.getTime() >= search.checkOut.getTime())
        return false;

      return true;
    })
    .sort((a, b) => a.availableAt.getTime() - b.availableAt.getTime());
  const calculatedFees = calculateRoomFees(fees, roomAvailability ?? []);
  const roomRate = roomAvailability?.reduce((total, night) => total + night.roomRate, 0) ?? 0;

  // const roomCostTotal = roomRate + feeTotal;
  const roomCostAverage = (roomRate + calculatedFees.total) / roomAvailability.length;

  return (
    <div className="hotel-room">
      <div className="hotel-room-info">
        <h5 className="hotel-room-name">{room.name}</h5>
        <h6 className="hotel-room-price">
          <div>
            <div><strong>{cToUsdStrPref(roomCostAverage)}</strong> per night avg.</div>
            <div><small><strong>Total</strong> {cToUsdStrPref(roomRate + calculatedFees.total)}</small></div>
          </div>
          <div>
            <InlineHelp>
              {fees.length === 0 ? (
                <div>
                  <strong>Excludes taxes and fees</strong>, which will be applied when reserving your room.
                </div>
              ) : (
                <>
                  <div className="mb-2">Includes taxes and fees of {cToUsdStrPref(calculatedFees.total)}:</div>
                  <ul className="mb-0">
                    {fees.map(fee => (
                      <li key={fee.name}>{fee.name} at {fee.type === HotelFeeType.Flat ? cToUsdStrPref(fee.value) : `${fee.value}%`}{fee.applyPerNight && ' (per night)'}</li>
                    ))}
                  </ul>
                </>
              )}
            </InlineHelp>
          </div>
        </h6>
        <div className="hotel-room-metadata">
          <span className="text-with-icon">
            <MaterialIcon name="hotel" /> <small>{hotel.name}</small>
          </span>
          {bedType && (
            <span className="text-with-icon">
              <MaterialIcon name="bed" /> <small>{bedType} Bed</small>
            </span>
          )}
          <span className="text-with-icon">
            <MaterialIcon name="person" /> <small>Max {room.maxPersons} Guest{room.maxPersons > 1 ? 's' : ''}</small>
          </span>
          <div className="hotel-room-badges badge-list">
            {hotel.isMainHotel && (
              <Badge color="primary">
                <MaterialIcon name="star" />
                Main Venue Hotel
              </Badge>
            )}
          </div>
        </div>
      </div>
      <div className="hotel-room-details">
        <div className="hotel-room-description" dangerouslySetInnerHTML={{ __html: sanitizeRoomDescriptions(room) ?? '<p><i>No description provided.</i></p>' }} />
        <div className="hotel-room-availability-list">
          {roomAvailability?.map(availability => (
            <HotelRoomAvailability key={availability.availableAt.toISOString()} availability={availability} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface FeeTotal {
  fee: HotelFee;
  total: number;
}

interface FeeBreakdown {
  total: number;
  fees: FeeTotal[];
}

const calculateRoomFees = (fees: HotelFee[], nights: RoomAvailability[]): FeeBreakdown => {
  if (fees.length === 0)
    return { total: 0, fees: [] };

  const feeTotals = fees.map(fee => ({ fee, total: 0 }));
  const perNightFees = fees.filter(fee => fee.applyPerNight);

  const updateFeeTotal = (fee: HotelFee, total: number): void => {
    const feeTotal = feeTotals.find(feeTotal => feeTotal.fee.name === fee.name);
    if (feeTotal) {
      feeTotal.total = feeTotal.total + total;
    }
  };

  const calculateFees = (fees: HotelFee[], subtotal: number): number => {
    return nights.reduce(
      (total, night) => {
        // Add up the fixed fees for the night
        const fixedFeeTotal = fees
          .filter(fee => fee.type === HotelFeeType.Flat)
          .reduce((total, fee) => {
            updateFeeTotal(fee, fee.value);
            return total + fee.value;
          }, 0);
  
        // Add up the percentage fees for the night
        const nightFeeTotal = fees
          .filter(fee => fee.type === HotelFeeType.Percentage)
          .reduce((total, fee) => {
            const feeAmount = (night.roomRate + total) * (fee.value / 100);
            updateFeeTotal(fee, feeAmount);
            return total + feeAmount;
          }, fixedFeeTotal);

  
        // Add the subtotal and the percentage fees to the existing total
        return total + nightFeeTotal;
      }, subtotal
    );
  };

  const nightlyFeeTotal = calculateFees(perNightFees, 0);

  const roomTotal = nights.reduce((total, night) => total + night.roomRate, 0);

  // TODO: Add boolean for if nightly fees are taxable or not, currently assuming they are not
  const nonNightlyFeeTotal = fees
    .filter(fee => !fee.applyPerNight)
    .reduce((total, fee) => {
      let newTotal = total;
      if (fee.type === HotelFeeType.Percentage) {
        const feeAmount = (roomTotal + total) * (fee.value / 100);
        updateFeeTotal(fee, feeAmount);
        newTotal = total + feeAmount;
      } else {
        updateFeeTotal(fee, fee.value);
        newTotal = total + fee.value;
      }
      return newTotal;
    }, 0);

  return {
    total: nightlyFeeTotal + nonNightlyFeeTotal,
    fees: feeTotals,
  };
};

/**
 * Remove all of the style attributes that affect background-color, color, font-size, font-weight, and font-family
 * 
 * @param room Room to sanitize the description of
 */
const sanitizeRoomDescriptions = (room: RoomBlock): string | undefined => {
  const regex = new RegExp(/((color|font-size|font-family|background-color): ?(&quot;.*&quot;, )?[#"a-zA-Z0-9-.]*;)/gi);

  return room.description?.replace(regex, '');
};

const getRoomTypeFromName = (room: RoomBlock): string | null => {
  // TODO: Find a better way to do this
  if (room.name.toLowerCase().includes('king')) {
    return 'King';
  }

  if (room.name.toLowerCase().includes('queen')) {
    return 'Queen';
  }

  if (room.name.toLowerCase().includes('double')) {
    return 'Double';
  }

  return null;
}