import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { DateInput, IncrementPicker, MaterialIcon } from './common';

import './HotelRoomSearch.scss';

export interface SearchFilter {
  checkIn?: Date;
  checkOut?: Date;
  minBeds: number;
  minGuests: number;
}

interface Props {
  defaultCheckIn?: Date;
  defaultCheckOut?: Date;
  defaultMinBeds?: number;
  defaultMinGuests?: number;
  onChange: (filter: SearchFilter) => void;
}

export const HotelRoomSearch: React.FC<Props> = ({
  defaultCheckIn, defaultCheckOut, defaultMinBeds = 1, defaultMinGuests = 1, onChange,
}) => {
  const [checkIn, setCheckIn] = useState<Date | undefined>(defaultCheckIn);
  const [checkOut, setCheckOut] = useState<Date | undefined>(defaultCheckOut);
  const [minBeds, setMinBeds] = useState<number>(defaultMinBeds);
  const [minGuests, setMinGuests] = useState<number>(defaultMinGuests);

  const onUpdateFilter = useCallback(() => {
    onChange({
      checkIn,
      checkOut,
      minBeds,
      minGuests,
    });
  }, [checkIn, checkOut, minBeds, minGuests, onChange]);

  return (
    <div className="hotel-search hotel-search-component">
      <div className="hotel-search-element">
        <div className="hotel-search-element-icon">
          <MaterialIcon name="calendar_month" />
        </div>
        <div className="hotel-search-element-inner">
          <div className="hotel-search-element-label">Check-in</div>
          <div>
            <DateInput defaultValue={defaultCheckIn} onChange={setCheckIn} />
          </div>
        </div>
      </div>
      <div className="hotel-search-element">
        <div className="hotel-search-element-icon">
          <MaterialIcon name="calendar_month" />
        </div>
        <div className="hotel-search-element-inner">
          <div className="hotel-search-element-label">Check-out</div>
          <div>
            <DateInput type="date" defaultValue={defaultCheckOut} onChange={setCheckOut} />
          </div>
        </div>
      </div>
      <div className="hotel-search-element">
        <div className="hotel-search-element-icon">
          <MaterialIcon name="bed" />
        </div>
        <div className="hotel-search-element-inner">
          <div className="hotel-search-element-label">Number of beds</div>
          <div>
            <IncrementPicker min={1} max={10} defaultValue={defaultMinBeds} onChange={setMinBeds} />
          </div>
        </div>
      </div>
      <div className="hotel-search-element">
        <div className="hotel-search-element-icon">
          <MaterialIcon name="group" />
        </div>
        <div className="hotel-search-element-inner">
          <div className="hotel-search-element-label">Number of guests</div>
          <div>
            <IncrementPicker min={1} max={4} defaultValue={defaultMinGuests} onChange={setMinGuests} />
          </div>
        </div>
      </div>
      <div className="hotel-search-element hotel-search-button" style={{ flex: 1 }}>
        <div>
          <Button color="light" outline block onClick={onUpdateFilter}>Update Search</Button>
        </div>
      </div>
    </div>
  );
};