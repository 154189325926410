import { LogicErrorArgs } from '@conventioncatcorp/hapi-utils/lib/plugins/decorations';

export enum LogicError {
  Test = -1,
  None = 0,
  PasskeyUnknownError = 1,
  AlreadyInQueue,
  RegistrationRequired,
  PaidRegistrationRequired,
  StripeNotConfigured,
  StripeAuthFailed,
  StripeCardAlreadyUsed,
  StripePrepaidCardNotAllowed,
  StripeTokenRequired,
  ConventionNotFound,
  TemplateParsingError,
  WaveNotEligible,
  WaveEntriesNotYetOpen,
  WaveEntriesClosed,
  WaveAlreadyWon,
  WavePreferenceInvalid,
}

export const logicErrorMessages: {
  [key in LogicError]: string | ((args: LogicErrorArgs) => string)
} = {
  [LogicError.Test]: 'test',
  [LogicError.None]: 'dummy',
  [LogicError.PasskeyUnknownError]: 'Passkey request resulted in an unexpected error',
  [LogicError.AlreadyInQueue]: 'You have already entered this wave.',
  [LogicError.RegistrationRequired]: 'This event requires that you register before entering',
  [LogicError.PaidRegistrationRequired]: 'This event requires that you pay for registration before entering',
  [LogicError.StripeNotConfigured]: 'Stripe is not configured for this event',
  [LogicError.StripeAuthFailed]: 'Your card was declined by our payment processor.',
  [LogicError.StripeCardAlreadyUsed]: 'The card you entered has already been used to enter this wave, and cannot be used again.',
  [LogicError.StripePrepaidCardNotAllowed]: 'Prepaid debit cards are not accepted for entering this wave.',
  [LogicError.StripeTokenRequired]: 'A valid Stripe token is required to enter this wave.',
  [LogicError.ConventionNotFound]({ host }) {
    return `Could not find convention with the domain: ${host}`;
  },
  [LogicError.TemplateParsingError]({ errorMessage }) {
    return `Error parsing template: ${errorMessage}`;
  },
  [LogicError.WaveNotEligible]: 'You do not meet all of the criteria for this wave. Please check the entry criteria and try again.',
  [LogicError.WaveEntriesNotYetOpen]: 'Entries for this wave are not yet open. Please check the entry schedule and try again.',
  [LogicError.WaveEntriesClosed]: 'Entries for this wave are now closed.',
  [LogicError.WaveAlreadyWon]: 'You have already won one or more waves, and are not eligible to enter again.',
  [LogicError.WavePreferenceInvalid]: 'One or more of your preferences are invalid. Please check your preferences and try again.'
};
