import React, { useEffect, useState } from "react";
import { QueueInfo, Reservation } from "../../../common/models"
import { captureError, dateToUserFormat } from "../../utils";
import { Alert } from "reactstrap";
import { InlineHelp } from "@conventioncatcorp/common-fe";
import { Countdown } from "../common";
import { WaveInfoEntryStatusProps } from "./WaveInfoEntryStatus";

interface WaveInfoWinProps extends WaveInfoEntryStatusProps {
  queueInfo: QueueInfo;
}

export const WaveInfoWin: React.FC<WaveInfoWinProps> = (props) => {
  const [ reservations, setReservations ] = useState<Reservation[] | null>(null);
  const { release, short } = props;

  useEffect(() => {
    api.getMyReservations()
      .then(setReservations)
      .catch(captureError);
  }, [release]);

  if (reservations === null) {
    return (
      <div>
        <p>Loading, just a moment...</p>
      </div>
    )
  }

  if (reservations.length > 0 && reservations.some(r => r.reservationId)) {
    const reservation = reservations[0];

    if (short) {
      return (
        <div>
          <strong>Reservation Completed!</strong> Your reservation number is <strong>{reservation?.reservationId}</strong>.
        </div>
      );
    }

    return (
      <div>
        <Alert color="success">
          <div className="mb-2">
            <strong>Reservation Completed!</strong> Your reservation number is <strong>{reservation?.reservationId}</strong>.
          </div>
          <div className="mt-3 small-text">
            If you need to make any changes to your reservation, please use the "Modify Reservation" link provided in your confirmation email, or contact the hotel directly.
          </div>
        </Alert>
      </div>
    );
  }
  
  return release.type == 'draw' ? <RandomDrawWin {...props} /> : <FCFSWin {...props} />;
}

const RandomDrawWin: React.FC<WaveInfoWinProps> = ({ release, short }) => {
  const endDate = dateToUserFormat(release.releaseEnd);

  if (short) {
    return <div>Congratulations! You're eligible to reserve a room until <strong>{endDate}</strong>.</div>;
  }

  return (
    <>
      <hr />
      <Alert color="success">
        <div className="mb-2">
          <strong>Congratulations!</strong> You're now eligible to reserve a room. You must complete your reservation before <strong>{endDate}</strong>, or your entry will be forfeit.
        </div>
        <div className="mt-3 small-text">
          <strong>Please Note</strong>: Your unique link will automatically pre-fill most of the information required by Passkey from your ConCat account. For this reason,
          {' '}<strong>you should not share this link with anyone you do not trust!</strong>
        </div>
      </Alert>
    </>
  );
};

const FCFSWin: React.FC<WaveInfoWinProps> = ({ queueInfo, release, short, updateQueueInfo }) => {
  const endDate = dateToUserFormat(release.releaseEnd);
  const hasValidityPeriod = release.validityPeriod && release.validityPeriod > 0;
  const reservationInfo = queueInfo.reservationInfo;

  if (!reservationInfo) {
    return short ? (
      <div>
        <strong>Your reservation link is being generated.</strong> Please keep this page open, and do not refresh the page.
      </div>
    ) : null;
  }

  // If no URL, show the expired status
  if (!reservationInfo.url) {
    return short ? (
      <div>
        <strong>Your reservation window has expired.</strong> You can re-enter the draw for this wave, but you will lose your place in line.
      </div>
    ) : null;
  }

  if (hasValidityPeriod && reservationInfo.expiresAt) {
    return (
      <div>
        <strong>You're eligible to reserve a room!</strong> You have <Countdown target={reservationInfo.expiresAt!} onExpiry={updateQueueInfo} /> remaining to complete your reservation.
        <InlineHelp>
          <div>
            You must complete your reservation before the timer expires, or you will lose your place in line.
          </div>
        </InlineHelp>
        <div className="mt-3 small-text">
          <strong>Please Note</strong>: Your unique link will automatically pre-fill most of the information required by Passkey from your ConCat account. For this reason,
          {' '}<strong>you should not share this link with anyone you do not trust!</strong>
        </div>
      </div>
    );
  }

  if (short) {
    return null;
  }

  return (
    <div>
      <strong>You're eligible to reserve a room!</strong> You must complete your reservation before <strong>{endDate}</strong>, or your entry will be forfeit.
      <div className="mt-3 small-text">
        <strong>Please Note</strong>: Your unique link will automatically pre-fill most of the information required by Passkey from your ConCat account. For this reason,
        {' '}<strong>you should not share this link with anyone you do not trust!</strong>
      </div>
    </div>
  );
};
