import React from "react";
import { EmptyState } from "./common";
import { isLogicError, useHeaderTitle } from "../utils";
import { LogicError } from "../../common/errorCodes";

import './SystemError.scss'

interface Props {
  error: Error;
}

export const SystemError: React.FC<Props> = ({ error }) => {
  if (error && isLogicError(error, LogicError.ConventionNotFound)) {
    useHeaderTitle('Not Yet Ready - BookingBun');

    return (
      <div className="system-error-preparing-convention m-3">
        <div className="system-error-logo">
          <img src='https://concat.app/img/ConCatLogo.svg' alt='ConCat Logo' />
          <div>
            <a href='https://concat.app' target='_blank' rel='noopener noreferrer'>
              Powered by ConCat
            </a>
          </div>
        </div>
        <div>
          <EmptyState
            title="Preparing Convention..."
            details={[
              'We\'re preparing your convention for first time use with BookingBun, but we\'re not quite ready yet.',
              '',
              'Please wait a few minutes and then refresh the page.',
            ]}
            icon='cloud_sync'
          />
        </div>
      </div>
    )
  }

  return (
    <div className="system-error m-3">
      <EmptyState
        title="System Error"
        details={[
          'We\'re sorry, but an unexpected error is preventing the application from loading. This is likely a temporary issue, so please try again in a few minutes.',
          '',
          'Error message: ' + error.message,
        ]}
        icon='warning'
      />
    </div>
  );
};
