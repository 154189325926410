import { Addressed, Reservation, RoomBlock } from ".";

export interface Hotel extends Addressed {
  description: string | null;
  fees: HotelFee[];
  id: number;
  name: string;
  isMainHotel: boolean;

  phone: string | null;
  website: string | null;

  // Admin props
  attendeeCode: string | null; // the access code representing this hotel in the third-party integration
  managedBy: string | null; // indicates the third-party integration managing this hotel
  externalHotelId: string | null; // indicates the ID of the hotel in the third-party integration
  eventId: number | null; // indicates the ID of the event in the third-party integration
  visibility: boolean | null; // indicates whether this hotel is visible to the public
  roomBlocks: RoomBlock[] | null; // indicates the room blocks associated with this hotel
  reservations: Reservation[] | null;

  createdAt?: Date;
  updatedAt?: Date;

  integration?: {
    id: number;
    name: string;
    type: string;
    lastSyncedAt: Date | null;
    lastManualSyncAt: Date | null;
  }
}

export interface HotelCreate extends Omit<Hotel, 'id' | 'fees'> {}

export enum HotelFeeType {
  Percentage = 'percentage',
  Flat = 'flat',
}

export interface HotelFee {
  id: number;
  name: string;
  type: HotelFeeType;
  isTax: boolean;
  value: number;
  applyPerNight: boolean;
}